import React, { useEffect, useState } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import AuthorizeUser from "./Api/AuthorizeUser";
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from './AppContextProvider';

const Authorize = (props) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [isAuthorizationFailed, setIsAuthorizationFailed] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const {  redirectUri, setIsAuthorizedState, accessToken, setUserAccessStatus} = useAuth();
    const allowedRoutes = {
      "/PatientDetails":"/patients",
      "/patients":"/patients",
      "/visits":"/visits",
      "/admin":"/patients"
    };
    const [loader, setLoader] = useState(false);
    useEffect(() => {
      setLoader(true);
        if (!authState?.isAuthenticated) {
          // When user isn't authenticated, forget any user info
        } else {
          oktaAuth.getUser().then((info) => {
            AuthorizeUser(info.email, authState.accessToken.accessToken)
            .then((response) => {
              if(response === "access_denied"){
                setIsAuthorizationFailed(true);
                setIsAuthorizedState(false);
                setLoader(false);
                setUserAccessStatus(response);
              }
              else{
                setUserAccessStatus(response);
                setIsAuthorizedState(true);
                setLoader(false);
                history.replace({
                  pathname: redirectUri === ""? "/patients":allowedRoutes[redirectUri],
                });
              }
            })
            .catch((err) => {
              setIsAuthorizedState(false);
              setIsAuthorizationFailed(true);
              setLoader(false);
            });
          });
        }
      }, [authState]);
  return (
    <>
      {loader && 
        <div id="overlay">
          <div id="loading" class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
          <div id="loaderText" style={{color:"#fff"}}>
            Please wait while we check for your access
          </div>
        </div>}
        {isAuthorizationFailed &&
        <p style={{fontWeight:"bold", marginTop:"20px"}}>You have not been Authorized to use Redcap, Please contact redcap administrator for access.</p>
        }
    </>
  )
}

export default Authorize