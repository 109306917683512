import React, { useState } from 'react'
import { useAuth } from './AppContextProvider';
import getEmployeeId from './Api/GetEmployeeId';
import { Spinner } from "react-bootstrap";
import { useOktaAuth } from '@okta/okta-react';


const LockScreenDialog = (props) => {
	const { oktaAuth } = useOktaAuth();
    const [empId, setEmpId] = useState();
	const [loader, setLoader] = useState(false);
	const [isInvalidInput, setIsInvalidInput] = useState(false);
    const inputHandler = (e) => {
        setEmpId(e.target.value);
    }
	const { userInfo, accessToken } = useAuth();
    const handleSubmit = () => {
		setLoader(true);
		getEmployeeId(userInfo.email, empId, accessToken)
		.then((response) => {
		  
		  if(response){
			localStorage.setItem("locked",false)
            props.setShowLockedScreen(false);
			setLoader(false);
			setIsInvalidInput(false);
		  }
		  else{
			setLoader(false);
			setIsInvalidInput(true);
		  }
		})
		.catch((err) => {
			setLoader(false);
		  	setIsInvalidInput(true);
		});
    }
	const logout = async () => {
		await oktaAuth.signOut({
		  postLogoutRedirectUri: window.location.origin+'/logout'
		});
		localStorage.setItem("locked", false)
	  };
  return (
    <div id="overlay2">
        <div id="pin">
			<div class="row ">
				<div class="col-12">
					<div class="row m-0 w-100 p-0 d-flex justify-content-center">
						<div class="col-12 d-flex justify-content-left m-0 p-1">
							<div class="text-field w-100">
								<label class="lockScreenDetails">Enter Your Employee Id or Badge Number</label>
							</div>
						</div>
						<div class="col-12 d-flex justify-content-left m-0 p-1">
							<div class="text-field w-100">
								<span class="lockScreenSubText">Logged in user</span> : <label style={{fontWeight:"bolder"}}>{userInfo?.email}</label>
							</div>
						</div>
					</div>
					<div class="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
						<div class="col-10 d-flex justify-content-left m-0 p-1">
							<div class="text-field w-100">
								<input class="w-100 finInput" required type="password" autocomplete="off" id="pinInput" onChange={(e) => inputHandler(e)}/>
							</div>
						</div>
					</div>
				</div>
				{isInvalidInput && 
				<div className="row m-0 w-100 p-0 pb-2 d-flex justify-content-center">
					<div className="col-10 d-flex justify-content-left m-0 p-1">
						<div className="text-field w-100">
							<p style={{margin:"0px", color:"red", fontWeight:"light" }}>Please enter a valid employee id or badge number</p>
						</div>
					</div>
				</div>}
			</div>

			<div class="row d-flex justify-content-center mt-1">
				<div class="col-6 d-flex justify-content-center">
					<div class="submit_button-pin" style={{backgroundColor: "#004879"}} onClick={() => handleSubmit()}>
						{loader ? <Spinner/> : "Submit"}
					</div>
				</div>
			</div>
			<div class="row d-flex justify-content-center mb-1">
				<div class="col-6 d-flex justify-content-center">
					<div class="navTabLogout" id="logout" onClick={logout}>Logout</div>
				</div>
			</div>
		</div>
    </div>
  )
}

export default LockScreenDialog