import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareSquare, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import "./VisitList.css";

const SurveyConfirmation = (props) => {
  const { patientsList, surveyHistories, setShowConfirmationScreen, setPatientList, onClickSendSurvey, setShowScheduleConfirmation } = props;

  const [globalCheckBox, setGlobalCheckBox] = useState(true);
  
  const handleSurveyCheckBox = (pid, instrumentName, recordId) => {
    var tempArr = structuredClone(patientsList);
    var patientIndex = tempArr.findIndex(record => record.redcap_person_id === recordId);
    if(patientIndex > -1){
      const surveyIndex = tempArr[patientIndex].surveys.findIndex(survey => survey.pid === pid);
      if (surveyIndex > -1) {
        const instrumentIndex = tempArr[patientIndex].surveys[surveyIndex].instruments.findIndex(instrument => instrument.instrumentName === instrumentName);
        if (instrumentIndex > -1) {
          
          
          tempArr[patientIndex].surveys[surveyIndex].instruments[instrumentIndex].status = tempArr[patientIndex].surveys[surveyIndex].instruments[instrumentIndex].status === 1 ? 0 : 1;
        } 
      }
      
      setPatientList(tempArr);
    }
  }

  function surveyExists( recordId, pid, instrumentName) {
    const patient = patientsList.find(record => record.redcap_person_id === recordId);
    const survey = patient.surveys.find(survey => survey.pid === String(pid));
    if (!survey) {
        return false;
    }
    const instrument = survey.instruments.find(instrument => instrument.instrumentName === instrumentName);
    if(instrument){
      if(instrument.status === 1){
        return true;
      }
      else{
        return false;
      }
    }
    return false;
  }

  const checkIfPatientExists = (recordId) => {
    const patient = patientsList.find(record => record.redcap_person_id === recordId);
    return patient.isSelected;
  }

  const handlePatientCheckBox = (recordId) => {
    var tempArr = structuredClone(patientsList);
    const patient = tempArr.find(record => record.redcap_person_id === recordId);
    patient.isSelected = !patient.isSelected;
    
    setPatientList(tempArr);
    areAllPatientsSelected(tempArr);
  }

  const areAllPatientsSelected = (currentList) => {
    for(var i = 0; i < currentList.length;i++){
      if(currentList[i].isSelected === false){
        setGlobalCheckBox(false)
        return;
      }
    }
    setGlobalCheckBox(true);
  }
   
  const handleGlobalCheckBox = () => {
    var tempArr = structuredClone(patientsList);
    
    if(globalCheckBox){
      for(var i = 0; i < tempArr.length; i++){
        tempArr[i].isSelected = false;
      }
      setGlobalCheckBox(false);
    }
    else{
      for(var j = 0; j < tempArr.length; j++){
        tempArr[j].isSelected = true;
      }
      setGlobalCheckBox(true);
    }
    
    setPatientList(tempArr);
  }

  const findSurveyHistory = (recordId, formName) => {
    var surveyHistory =  surveyHistories.find(record => record.recordId === recordId && record.formName === formName);
    if(surveyHistory){
      return (
        <div style = {{fontSize: "smaller", marginLeft: "10px", color: "black"}}>
          {surveyHistory.status === "Completed" ?
            <>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "18px", color: "green", marginRight:"5px"}}
            />
            {surveyHistory.status}&nbsp;({surveyHistory.lastCompleted})
            </> 
            : 
            <>
            <FontAwesomeIcon
              icon={faShareSquare}
              style={{ fontSize: "18px", color: "#FF7F00", marginRight:"5px"}}
            />
            {surveyHistory.status}&nbsp;({surveyHistory.lastCompleted})
            </>
          }
        </div>
      );
    }
  }
  
  useEffect(() =>{
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },[]);

  return (
    <>
      <div id="vistCronStatus">
        <div className="row patientwithforms d-flex">
          <div className="col-lg-10 col-sm-12 d-flex">
            <div style={{ color: "#004879", marginRight: "10px" }}>
              {patientsList.length} Recipients
            </div>
          </div>
          <div id="previousscreen" className="col-lg-2 col-sm-12" onClick={() => setShowConfirmationScreen(false)} >
            Previous Screen
          </div>
        </div>
        <div className="d-none d-lg-block">
          <div className="row justify-content-center align-item-center p-3 visitpatientDataTop">
            <div className="col-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
              <span className="patientsinfoTop">
                <input
                  type="checkbox"
                  className="PatientFormCheckbox"
                  id="PatientFormCheckbox"
                  name="Locked"
                  style={{ height: "25px", width: "25px" }}
                  checked={globalCheckBox}
                  onChange={handleGlobalCheckBox}
                />
              </span>
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
              <span className="patientsinfoTop">Patients</span>
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
              <span className="patientsinfoTop">Contact Method</span>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
              <span className="patientsinfoTop">Survey Selected</span>
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
              <span className="patientsinfoTop">Preferred Language</span>
            </div>
          </div>
        </div>
        {patientsList.map((patient) => (
          <div
            className="row d-flex justify-content-center  align-item-center p-3 patientData"
            style={{ marginLeft: "15px", marginRight: "15px" }}
          >
            <div className="col-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
              <input
                type="checkbox"
                className="PatientFormCheckbox"
                id={patient.redcap_person_id}
                name="Locked"
                defaultChecked // or checked for controlled component
                style={{ height: "25px", width: "25px" }}
                onChange={() => handlePatientCheckBox(patient.redcap_person_id)}
                checked = {checkIfPatientExists(patient.redcap_person_id)}
              />
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
              <span className="d-lg-none" style={{ color: "black" }}>
                Patient: &nbsp;
              </span>
              <span className="patientsinfo apEmail">
                {/* {schedule.mrn} */}{patient.patient_name}
              </span>
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
              <span className="d-lg-none" style={{ color: "black" }}>
                Contact Method: &nbsp;
              </span>
              <span className="patientsinfo apEmail">
                {(patient.appointment.appt_communication_method === "Text" || patient.appointment.appt_communication_method === "Text (Enter number below)" || patient.appointment.appt_communication_method.toLowerCase() === "phone") ?
                  <>
                    {patient.appointment.appt_communication_number}
                  </> :
                  <>{patient.appointment.appt_communication_email}</>}
              </span>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12  justify-content-left mb-1">
              {patient.surveys &&
                <>
                  {patient.surveys.map((survey) => {
                    return (
                      <>
                        {survey.instruments.map((instrument) => {
                          return (

                            <>

                              <span className="patientsinfoTop">
                                <input
                                  type="checkbox"
                                  className="PatientFormCheckbox"
                                  onChange={() => handleSurveyCheckBox(survey.pid, instrument.instrumentName, patient.redcap_person_id)}
                                  name="Locked"
                                  checked = {surveyExists(patient.redcap_person_id, survey.pid, instrument.instrumentName)}
                                  style={{ height: "15px", width: "15px" }}
                                />&nbsp;&nbsp;
                                <div className='v_ellipsis'>{instrument.instrumentLabel}</div> {findSurveyHistory(patient.redcap_person_id, instrument.instrumentName)}
                              </span>

                            </>
                          )
                        })}
                      </>
                    )
                  })}
                </>}
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none" style={{ color: "black" }}>
                Preferred Language: &nbsp;
              </span>
              <span className="patientsinfo">
                {(patient.preferred_language === "" || patient.preferred_language === "null") ? "English" : patient.preferred_language}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div style={{marginBottom:"100px", padding:"20px", display:"flex", justifyContent:"space-between", width:"25vw"}}>
        <button
          className="searchButton  d-flex justify-content-around"
          id="searchButton"
          type="submit"
          onClick={() => onClickSendSurvey(new Date())}
          style={{marginRight:"5px"}}
          >
            <div id="SearchPatient" style={{fontWeight:"bold"}}>
              Send Survey
            </div>
          </button>
          <button
          className="searchButton  d-flex justify-content-around"
          id="searchButton"
          type="submit"
          onClick = {() => setShowScheduleConfirmation(true)}
          >
            <div id="SearchPatient" style={{fontWeight:"bold"}}>
              Confirm Schedule
            </div>
          </button>
      </div>
    </>
  )
}

export default SurveyConfirmation