const Visitstatus = [
    {key: 0, value:"All"},
    { key: 4534, value: "Block" },
    { key: 4535, value: "Canceled" },
    { key: 4536, value: "Checked In" },
    { key: 4537, value: "Checked Out" },
    { key: 4538, value: "Confirmed" },
    { key: 4539, value: "Default" },
    { key: 4540, value: "Deleted" },
    { key: 4541, value: "Finalized" },
    { key: 4542, value: "Hold" },
    { key: 4543, value: "No Show" },
    { key: 4544, value: "Pending" },
    { key: 4545, value: "Rescheduled" },
    { key: 4546, value: "Scheduled" },
    { key: 4547, value: "Standby" },
    { key: 4548, value: "Unschedulable" },
    { key: 615666, value: "Request" },
    { key: 37718774, value: "Canceled Advice" },
    { key: 37718775, value: "Advice" },
    { key: 37718776, value: "Closed Advice" },
    { key: 37718777, value: "Advice Response" },
    { key: 61072708, value: "Assessed" },
    { key: 61072709, value: "Complete" },
    { key: 61072710, value: "Shortlisted" },
    { key: 61072711, value: "Discarded" },
  ];
export default Visitstatus;