import React, { useEffect, useState } from "react";
import "./EmailBox.css";
import TagsInput from "react-tagsinput";
import { useAuth } from "../AppContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
export const SentForm = (props) => {
  const { patientData, formData, setFormData, startSurvey } = props;
  const [invalidEmail, setInvalidEmail] = useState("");
  const options = [
    { value: "English", label: "English" },
    { value: "Spanish", label: "Spanish" },
  ];
  const [currentLang, setCurrentLang] = useState(
    patientData.preferred_language !== "Spanish" ? "English" : "Spanish"
  );
  const { userInfo } = useAuth();
  const messageBody = {
    Spanish:
      "Children's Hospital Los Angeles tiene un nuevo formulario para patient_name Por favor, haga clic en el enlace para completar el formulario lo más pronto posible. Si tiene alguna pregunta, llame a su clínica.\n",
    English:
      "Children's Hospital Los Angeles has a new form for patient_name. Please click on the button to complete the form as soon as possible. If you have questions, call your clinic.\n",
  };
  useEffect(() => {
    patientData.preferred_language =
      patientData.preferred_language !== "Spanish" ? "English" : "Spanish";
    var uniqueMessageBody = messageBody[patientData.preferred_language].replace(
      "patient_name",
      patientData.patient_name
    );
    setFormData({
      ...formData,
      emailMessage: uniqueMessageBody,
      phoneMessage: uniqueMessageBody,
    });
  }, [patientData]);
  const handleLangChange = (e) => {
    setCurrentLang(e.target.value);
    var uniqueMessageBody = messageBody[e.target.value].replace(
      "patient_name",
      patientData.patient_name
    );
    setFormData({
      ...formData,
      emailMessage: uniqueMessageBody,
      phoneMessage: uniqueMessageBody,
    });
  };
  const inputHandler = (event) => {
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };
  const handleCheckboxChange = (event) => {
    setFormData({
      ...formData,
      [event.target.id]: !formData[event.target.id],
    });
  };

  const checkEmail = (event) => {
    if (!isValidEmail(event.target.value)) {
      alert("Please enter a valid email address for patient.");
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleTagsChange = (additionalPartyEmails) => {
    setInvalidEmail("");
    try {
      additionalPartyEmails.forEach((tag) => {
        if (!isValidEmail(tag)) {
          throw new Error(`Invalid email format: ${tag}`);
        }
      });
      const noDuplicateTags = additionalPartyEmails.filter(
        (v, i) => additionalPartyEmails.indexOf(v) === i
      );
      const duplicateEntered =
        additionalPartyEmails.length !== noDuplicateTags.length;
      if (duplicateEntered) {
        throw new Error(
          `${
            additionalPartyEmails[additionalPartyEmails.length - 1]
          }: already exists in additional party emails.`
        );
      }
      setFormData({
        ...formData,
        additionalPartyEmails,
      });
    } catch (error) {
      setInvalidEmail(error.message);
    }
  };

  return (
    <>
      <div id="overlay2"></div>
      <div id="emailBox">
        <div>
          <div className="row ">
            <div className="col-12">
              <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center" />
                <div className="col-10 d-flex justify-content-left m-0 p-0 ">
                  <label style={{ fontWeight: "bold" }}>
                    Patient's Preferred Langauge
                  </label>
                </div>
                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center" />
              </div>
              <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center" />

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                  <select
                    className="finInput"
                    id="currentlanguage"
                    onChange={handleLangChange}
                    value={currentLang}
                  >
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-12">
              <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center" />

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                  <div className="text-field w-100">
                    <label style={{ fontWeight: "bold" }}>
                      Patient's Phone Number
                    </label>
                  </div>
                </div>
                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center">
                  <div></div>
                </div>
              </div>
              <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center">
                  <input
                    type="checkbox"
                    id="contactTypePhone"
                    name="contactTypeText"
                    checked={formData.contactTypePhone}
                    onChange={(e) => handleCheckboxChange(e)}
                    style={{ height: "25px", width: "25px" }}
                  />
                </div>

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                  <div className="text-field w-100">
                    <input
                      className="w-100 finInput"
                      required
                      type="phone"
                      autoComplete="off"
                      id="phone"
                      value={formData.phone}
                      onChange={(e) => inputHandler(e)}
                      disabled={!formData.contactTypePhone}
                    />
                  </div>
                </div>
                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center">
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center" />

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                  <div className="text-field w-100">
                    <label style={{ fontWeight: "bold" }}>
                      Phone Text Message
                    </label>
                  </div>
                </div>
                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
              </div>
              <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center" />

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                  <div className="text-field w-100">
                    <textarea
                      className="w-100 finInput"
                      readOnly
                      required
                      rows="5"
                      cols="33"
                      type="textarea"
                      value={formData.phoneMessage}
                      autoComplete="off"
                      id="phoneText"
                    ></textarea>
                  </div>
                </div>
                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center" />

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                  <div className="text-field w-100">
                    <label style={{ fontWeight: "bold" }}>
                      Patient's Email
                    </label>
                  </div>
                </div>
                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
              </div>
              <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center">
                  <input
                    type="checkbox"
                    id="contactTypeEmail"
                    checked={formData.contactTypeEmail}
                    onChange={(e) => handleCheckboxChange(e)}
                    style={{ height: "25px", width: "25px" }}
                  />
                </div>

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                  <div className="text-field w-100">
                    <input
                      className="w-100 finInput"
                      type="email"
                      required
                      autoComplete="off"
                      id="email"
                      value={formData.email}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => checkEmail(e)}
                      disabled={!formData.contactTypeEmail}
                    />
                  </div>
                </div>
                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center">
                  <div id="showOtherEmail"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center" />

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                  <div className="text-field w-100">
                    <label style={{ fontWeight: "bold" }}>
                      Email Text Message
                    </label>
                  </div>
                </div>
                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
              </div>
              <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center" />

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                  <div className="text-field w-100">
                    <textarea
                      className="w-100 finInput"
                      readOnly
                      required
                      rows="5"
                      cols="33"
                      value={formData.emailMessage}
                      type="textarea"
                      autoComplete="off"
                      id="emailText"
                    ></textarea>
                  </div>
                </div>
                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex">
              <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center" />

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                  <div className="text-field d-flex w-100">
                    <input
                      type="checkbox"
                      checked={formData.notifylogedinUser}
                      id="notifylogedinUser"
                      style={{ height: "25px", width: "25px" }}
                      onChange={(e) => handleCheckboxChange(e)}
                    />
                    <br />
                    <div
                      className="loggedInuser"
                      id="notifylogedinEmail"
                      style={{ fontWeight: "bold" }}
                    >
                      &nbsp; Notify Me ({userInfo?.email})
                    </div>
                  </div>
                </div>

                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center">
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row m-0 w-100 p-0  d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center"></div>

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                  <div className="text-field w-100">
                    <label style={{ fontWeight: "bold" }}>
                      Additional Party Notifications
                    </label>
                  </div>
                </div>

                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center">
                  <div></div>
                </div>
              </div>
              <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center">
                  <input
                    type="checkbox"
                    id="notifyAdditionalParty"
                    checked={formData.notifyAdditionalParty}
                    onChange={(e) => handleCheckboxChange(e)}
                    style={{ height: "25px", width: "25px" }}
                  />
                </div>

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                  <div className="text-field w-100">
                    <TagsInput
                      value={formData.additionalPartyEmails}
                      onChange={handleTagsChange}
                      addOnBlur={handleTagsChange}
                      disabled={!formData.notifyAdditionalParty}
                      className="w-100 finInput tagInputBox"
                      inputProps={{ placeholder: " " }}
                    />
                  </div>
                </div>
                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center">
                  <div></div>
                </div>
              </div>
              <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                <div className="col-1 d-flex justify-content-left m-0 p-0 align-items-center">
                </div>

                <div className="col-10 d-flex justify-content-left m-0 p-0">
                <div
                  className="row m-0 w-100 p-0 d-flex justify-content-center"
                  style={{ padding: "0px" }}
                >
                  {invalidEmail !== ""  && (
                    <div style={{ padding: "0px" }}>
                      <span
                        style={{
                          color: "red",
                          fontWeight: "lighter",
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        {invalidEmail}
                      </span>
                      <span style={{ color: "black", fontWeight: "lighter" }}>
                        <br />
                        Please try again
                      </span>
                    </div>
                  )}
                </div>
                </div>
                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center">
                  <div></div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row d-flex justify-content-center pt-3"
            style={{ color: "#004879", textAlign: "center" }}
          >
            Please validate that the email and/or phone number is correct before
            sending.
          </div>

          <div className="row d-flex justify-content-center mb-3 mt-3">
            <div className="col-6 d-flex justify-content-center">
              <div
                className="submit_button"
                id="sendEmail"
                onClick={() => props.startSurvey("send")}
              >
                <div>Send </div>{" "}
                <span id="startIcon" className="d-button">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </span>
              </div>
            </div>

            <div className="col-6 d-flex justify-content-center">
              <div
                className="submit_button"
                id="submitCancel"
                onClick={props.openDialog}
              >
                <div>Cancel</div>
                <span id="cancelIcon" className="d-button">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
