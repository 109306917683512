import React, { useEffect, useState } from 'react';
import "./VisitList.css";
import TagsInput from 'react-tagsinput';
import { useAuth } from "../AppContextProvider";

const VisitsSurveyList = (props) => {
    const { formData, setFormData, surveysList, submitSurveys, isButtonDisabled, invalidEmail, setInvalidEmail } = props;
    const [preferredLanguage, setPreferredLanguage] = useState("English");
    const { userInfo } = useAuth();
    const options = [
        { value: 'English', label: 'English' },
        { value: 'Spanish', label: 'Spanish' },
    ];
    const messageBody = {
        "Spanish": "Children's Hospital Los Angeles tiene un nuevo formulario para patient_name Por favor, haga clic en el enlace para completar el formulario lo más pronto posible. Si tiene alguna pregunta, llame a su clínica.\n",
        "English": "Children's Hospital Los Angeles has a new form for patient_name. Please click on the button to complete the form as soon as possible. If you have questions, call your clinic.\n"
    };
    const handleChange = (event) => {
        setPreferredLanguage(event.target.value);
    };
    useEffect(() => {
        setFormData({ ...formData, emailTextMessage: messageBody[preferredLanguage], phoneTextMessage: messageBody[preferredLanguage] })
    }, [preferredLanguage])

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleNotifyCheck = () => {
        setFormData({...formData, notifyLoggedInUser : !formData.notifyLoggedInUser});
    }


    const handleGlobalCheckBox = () => {
        var selectedSurveys = formData.globalSurveys;
        if(formData.globalCheckBox){
            selectedSurveys = [];
        }
        else{
            if(surveysList.generalProjects && surveysList.generalProjects !== 0){
                for(var i=0; i<surveysList.generalProjects.length; i++){
                    var pid = String(surveysList.generalProjects[i].pid);
                    for(var j=0; j<surveysList.generalProjects[i].instruments.length; j++){
                        var instrumentName = surveysList.generalProjects[i].instruments[j].instrumentName;
                        var instrumentLabel = surveysList.generalProjects[i].instruments[j].instrumentLabel;
                        if(!surveyExists(pid, instrumentName, "global") && instrumentName !== "patient_experience"){
                            selectedSurveys = updateSelectedSurveys(selectedSurveys, pid, instrumentName, instrumentLabel);
                        }
                    }
                }
            }
        } 
        setFormData({...formData, globalSurveys: selectedSurveys, globalCheckBox: !formData.globalCheckBox})
    }

    const handleClinicalCheckBox = () => {
        var selectedSurveys = formData.clinicalSurveys;
        if(formData.clinicalCheckBox){
            selectedSurveys = [];
        }
        else{
            if(surveysList.locationProjects && surveysList.locationProjects !== 0){
                for(var i=0; i<surveysList.locationProjects.length; i++){
                    var pid = String(surveysList.locationProjects[i].pid);
                    for(var j=0; j<surveysList.locationProjects[i].instruments.length; j++){
                        var instrumentName = surveysList.locationProjects[i].instruments[j].instrumentName;
                        var instrumentLabel = surveysList.locationProjects[i].instruments[j].instrumentLabel;
                        if(!surveyExists(pid, instrumentName, "clinical") && instrumentName !== "patient_experience"){
                            selectedSurveys = updateSelectedSurveys(selectedSurveys, pid, instrumentName, instrumentLabel);
                        }
                    }
                }
            }
        } 
        setFormData({...formData, clinicalSurveys: selectedSurveys, clinicalCheckBox: !formData.clinicalCheckBox})
    }

    function areAllSurveysSelected(allSurveys, selectedSurveys) {
        return allSurveys.every(survey => {
            const selectedSurvey = selectedSurveys.find(sel => sel.pid.toString() === survey.pid.toString());
            if (!selectedSurvey) {
                return false;
            }
            return survey.instruments.every(instrument => {
                if (instrument.instrumentName === 'patient_experience') {
                    return true;
                }
                return selectedSurvey.instruments.some(selInst => 
                    selInst.instrumentName === instrument.instrumentName);
            });
        });
    }

    const handleSurveyCheckbox = (event, instrumentLabel) => {
        const [pid, instrumentName, type] = event.target.id.split('-');
        var selectedSurveys = [];
        if(type === "global"){
            selectedSurveys = formData.globalSurveys;
        }
        else{
            selectedSurveys = formData.clinicalSurveys;
        }
        selectedSurveys = updateSelectedSurveys(selectedSurveys, pid, instrumentName, instrumentLabel);
        if(type === "global"){
            var globalCheckBoxVal = false;
            if(formData.globalSurveys.length > 0){
                globalCheckBoxVal = areAllSurveysSelected(surveysList.generalProjects, selectedSurveys);
            }
            setFormData({ ...formData, globalSurveys: selectedSurveys, globalCheckBox : globalCheckBoxVal});
        }
        else{
            var clinicalCheckBoxVal = false;
            if(formData.clinicalSurveys.length > 0){
                clinicalCheckBoxVal = areAllSurveysSelected(surveysList.locationProjects, selectedSurveys);
                
            }
            setFormData({ ...formData, clinicalSurveys: selectedSurveys, clinicalCheckBox : clinicalCheckBoxVal});
        }
    }

    const updateSelectedSurveys = (selectedSurveys, pid, instrumentName, instrumentLabel) => {
        const surveyIndex = selectedSurveys.findIndex(survey => survey.pid === pid);
        if (surveyIndex > -1) {
            const instrumentIndex = selectedSurveys[surveyIndex].instruments.findIndex(instrument => instrument.instrumentName === instrumentName);
            if (instrumentIndex > -1) {
                selectedSurveys[surveyIndex].instruments.splice(instrumentIndex, 1);
                if (selectedSurveys[surveyIndex].instruments.length === 0) {
                    selectedSurveys.splice(surveyIndex, 1);
                }
            } 
            else{
                selectedSurveys[surveyIndex].instruments.push({
                    instrumentName: instrumentName,
                    instrumentLabel : instrumentLabel,
                    instance: 0,
                    status : 1
                });
            }
        } 
        else{
            selectedSurveys.push({
                pid: pid,
                instruments: [{
                    instrumentName: instrumentName,
                    instrumentLabel : instrumentLabel,
                    instance: 0, 
                    status : 1
                }]
            });
        }
        return selectedSurveys;
    }
    const handleTagsChange = (additionalPartyEmails) => {
        setInvalidEmail("");
        try {
            additionalPartyEmails.forEach((tag) => {
                if (!isValidEmail(tag)) {
                    throw new Error(`Invalid email : ${tag}`);
                }
            });
            const noDuplicateTags = additionalPartyEmails.filter((v, i) => additionalPartyEmails.indexOf(v) === i);
            const duplicateEntered = additionalPartyEmails.length !== noDuplicateTags.length;
            if (duplicateEntered) {
                throw new Error(`${additionalPartyEmails[additionalPartyEmails.length - 1]}: already exists in additional party emails.`)
            }
            setFormData({
                ...formData,
                additionalPartyEmails,
            });
        } catch (error) {
            setInvalidEmail(error.message);
        }
    };

    function surveyExists(pid, instrumentName, type) {
        var selectedSurveys = [];
        if(type === "global"){
            selectedSurveys = formData.globalSurveys;
        }
        else{
            selectedSurveys = formData.clinicalSurveys;
        } 
        const survey = selectedSurveys.find(survey => survey.pid === String(pid));
        if (!survey) {
            return false;
        }
        const instrumentExists = survey.instruments.some(instrument => instrument.instrumentName === instrumentName);
        return instrumentExists;
    }

    return (
        <>
            <div id="visitFormdata" className="col-lg-12 col-sm-12 ">
                <div id="expand" className="row d-flex">
                    <div className="col-12 expandheaderText" onClick={() => setFormData({...formData, isExpanded: !formData.isExpanded})}>
                        <img style={{ height: "24px", width: "24px" }} src="https://redcap-uat.chla.org/redcap_v13.8.3/Resources/images/chevron.png" alt="expand_logo"></img>
                        <div id="expandText">{formData.isExpanded ? "Collapse" : "Expand"}</div>
                    </div>
                    {formData.isExpanded ? 
                    <>
                    <div id="visitSelectsurveys" className="col-12 surveyandemail d-flex" onClick={() => setFormData({...formData, showSelectedSurveys: !formData.showSelectedSurveys})}>
                        <div>Select Surveys</div>
                        <div id="visitSurveyicon">{formData.showSelectedSurveys ? "▼" : "◀"}</div>
                    </div>
                    {formData.showSelectedSurveys && 
                    <div id="generalformsData">
                        <div className="row m-0 justify-content-center  align-item-center">
                            <div className = "col-12 visitSurveyData">
                                <input type="checkbox"  className="all"  id="all" name="Locked" checked={formData.globalCheckBox} onChange={handleGlobalCheckBox}  style={{height:"15px", width:"15px"}}/>
                                <div className = "formHeader"> Global Surveys
                                </div>
                            </div>
                        </div>
                        <div className="row m-0 d-flex justify-content-center  align-item-center p-3  ">
                            {(surveysList.generalProjects && surveysList.generalProjects.count !== 0) && 
                            <>
                                {surveysList.generalProjects.map((survey) => {
                                     var pid = survey.pid;
                                    return(
                                        <>
                                        {survey.instruments.map((instrument) => {
                                            return(
                                                <>
                                                {instrument.instrumentName !== "patient_experience" && 
                                                <div className = "col-lg-12 col-md-12 col-sm-12  mb-1 visitSurveyData" style={{padding:"10px"}}>
                                                    <div>
                                                        <input type="checkbox" id = {`${pid}-${instrument.instrumentName}-global`} name="Locked" onChange={(e) => handleSurveyCheckbox(e, instrument.instrumentLabel)} checked={surveyExists(pid, instrument.instrumentName, "global")}  style={{height:"15px", width:"15px"}} />
                                                    </div>
                                                    <div style = {{color:"#004879", fontSize:"smaller"}}>
                                                        {instrument.instrumentLabel}
                                                    </div>
                                                </div>
                                                }
                                                </>
                                            )
                                        })}
                                        </>
                                    )
                                })}
                            </>}
                        </div>
                        <div className="row m-0 justify-content-center  align-item-center">
                            <div className = "col-12 visitSurveyData">
                                <input type="checkbox" className="all"  id="all" name="Locked" onChange={handleClinicalCheckBox} checked={formData.clinicalCheckBox}  style={{height:"15px", width:"15px"}}/>
                                <div className = "formHeader"> Clinic Surveys
                                </div>
                            </div>
                        </div>
                        <div className="row m-0 d-flex justify-content-center  align-item-center p-3  ">
                            {(surveysList.locationProjects && surveysList.locationProjects.count !== 0) && 
                            <>
                                {surveysList.locationProjects.map((survey) => {
                                     var pid = survey.pid;
                                    return(
                                        <>
                                        {survey.instruments.map((instrument) => {
                                            return(
                                                <>
                                                {instrument.instrumentName !== "patient_experience" && 
                                                    <div className = "col-lg-12 col-md-12 col-sm-12  mb-1 visitSurveyData" style={{padding:"10px"}}>
                                                        <div>
                                                            <input type="checkbox" id = {`${pid}-${instrument.instrumentName}-clinical`} name="Locked"  onChange={handleSurveyCheckbox} checked={surveyExists(pid,instrument.instrumentName, "clinical")} style={{height:"15px", width:"15px"}} />
                                                        </div>
                                                        <div style = {{color:"#004879", fontSize:"smaller"}}>
                                                            {instrument.instrumentLabel}
                                                        </div>
                                                    </div>
                                                }
                                                </>
                                            )
                                        })}
                                        </>
                                    )
                                })}
                            </>}
                        </div>
                    </div>
                    }
                    <div id="visitEmail" className="col-12 surveyandemail d-flex" onClick={() => setFormData({...formData, showConfigureEmail: !formData.showConfigureEmail})}>
                        <div>Configure Email</div>
                        <div id="visitEmailicon">{formData.showConfigureEmail ? "▼" : "◀"}</div>
                    </div>
                    {formData.showConfigureEmail &&
                        <div id="surveysListEmail">
                            <div>
                                <div className="row ">
                                    <div className="col-12">
                                        <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                            <div className="col-12 d-flex justify-content-left m-0 p-0">
                                                <label>Patient's Preferred Langauge</label>
                                            </div>
                                        </div>
                                        <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                                            <div className="col-12 d-flex justify-content-left m-0 p-0">
                                                <select
                                                    className="finInput"
                                                    id="currentlanguage"
                                                    value={preferredLanguage} // This makes the select a controlled component
                                                    onChange={handleChange}
                                                >
                                                    {options.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}</select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-12">
                                        <div className="row m-0 w-100 p-0  d-flex justify-content-center">
                                            <div className="col-12 d-flex justify-content-left m-0 p-0">
                                                <div className="text-field w-100">
                                                    <label>Phone Text Message</label>
                                                </div>
                                            </div>
                                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                                                <div className="col-12 d-flex justify-content-left m-0 p-0">
                                                    <div className="text-field w-100">
                                                        <textarea
                                                            className="w-100 finInput"
                                                            readOnly
                                                            required
                                                            rows="5"
                                                            cols="33"
                                                            type="textarea"
                                                            value={formData.phoneTextMessage}
                                                            autoComplete="off"
                                                            id="phoneText"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-12">
                                                <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                                    <div className="col-12 d-flex justify-content-left m-0 p-0">
                                                        <div className="text-field w-100">
                                                            <label>Email Text Message</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                                                    <div className="col-12 d-flex justify-content-left m-0 p-0">
                                                        <div className="text-field w-100">
                                                            <textarea
                                                                className="w-100 finInput"
                                                                readOnly
                                                                required
                                                                rows="5"
                                                                cols="33"
                                                                value={formData.emailTextMessage}
                                                                type="textarea"
                                                                autoComplete="off"
                                                                id="emailText"
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 d-flex">
                                                <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                                                    <div className="col-12 d-flex justify-content-left m-0 p-0">
                                                        <div className="text-field d-flex w-100">
                                                            <input
                                                                type="checkbox"
                                                                checked={formData.notifyLoggedInUser}
                                                                id="notifylogedinUser"
                                                                style={{ height: "25px", width: "25px" }}
                                                                onChange={handleNotifyCheck}
                                                            />
                                                            &nbsp;
                                                            <div className="loggedInuser apEmail" id="notifylogedinEmail">
                                                                Notify Me ({userInfo?.email})
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row m-0 w-100 p-0  d-flex justify-content-center">
                                                    <div className="col-12 d-flex justify-content-left m-0 p-0">
                                                        <div className="text-field w-100">
                                                            <label>Additional Party Notifications</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                                    <div className="col-12 d-flex justify-content-left m-0 p-0">
                                                        <div className="text-field w-100">
                                                            <TagsInput
                                                                value={formData.additionalPartyEmails}
                                                                onChange={handleTagsChange}
                                                                addOnBlur={handleTagsChange}
                                                                className="w-100 finInput tagInputBox"
                                                                inputProps={{ placeholder: ' ' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center" style={{padding:"0px"}}>
                                                    {invalidEmail !== "" && 
                                                    <div style={{padding:"0px"}}>
                                                        <span style={{color:"red", fontWeight:"lighter", margin:"0px", padding:"0px"}}>{invalidEmail}</span>
                                                        <span style={{color:"black", fontWeight:"lighter"}}><br/>Please try again</span>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <button className="searchButton" style={{ marginTop: "20px" }}  onClick={submitSurveys} disabled={isButtonDisabled()}>
                        <div>Confirm Selection</div>
                    </button>
                    </>:<></>}
                </div>
            </div>
        </>
    )
}

export default VisitsSurveyList