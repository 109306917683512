import React from 'react';
import "../patients.css";

const EncounterErrorDialog = (props) => {
    
    const onClickOkay = () => {
        props.setEncounterError(false);
        props.setShowEncounterList(false);
        props.setIsSurvey(false);
    }
    const onClickSelect = () => {
        props.setEncounterError(false);
    }
    return (
        <div id="overlay2">
            <div id="encounterError">
                <div class="row">
                    <div class="d-flex col-12 justify-content-center align-items-center">
                        <span style={{ fontSize: "large", fontWeight: "bold", marginLeft: "5px", color:"black" }}>
                            To send this survey you need to select an encounter
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="d-flex col-12 justify-content-left align-items-center">
                        <span style={{ fontSize: "large", fontWeight: "bold", marginLeft: "10px", marginTop:"20px", color:"#805791" }}>
                            Are you sure you want to exit without selecting an encounter ?
                        </span>
                    </div>
                </div>
                <div class="row" style={{ marginTop: "20px" }}>
                    <div class="col-6 d-flex justify-content-end">
                        <div class="submit_button-pin" style={{ backgroundColor: "#004879",  width:"10rem" }} onClick={() => onClickSelect()}>
                            <div >Select Encounter</div>
                        </div>
                    </div>
                    <div class="col-6 d-flex justify-content-left">
                        <div class="submit_button-pin" style={{ backgroundColor: "#004879",  width:"10rem" }} onClick={() => onClickOkay()}>
                            <div >Cancel</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EncounterErrorDialog