import React, { useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./VisitList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import VistLookupPatientlist from './VistLookupPatientlist';

const AppointmentDateSelection = (props) => {
    const { setSearchParams, searchParams, searchResults, setShowSchedule, setShowStatus} = props;
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [currentDateSelection, setCurrentDateSelection] = useState("0");

    const handleDateSelectionChange = (e) => {
        setCurrentDateSelection(e.target.value);
        setStartDate(new Date());
        var currentEndDate = new Date(); 
        if(e.target.value === "0"){ 
            setEndDate(currentEndDate);
        }
        else if(e.target.value === "7"){
            currentEndDate.setDate(currentEndDate.getDate() + 7);
            setEndDate(currentEndDate);
        }
        else if(e.target.value === "14"){
            currentEndDate.setDate(currentEndDate.getDate() + 14);
            setEndDate(currentEndDate);
        }
        else if(e.target.value === "21"){
            currentEndDate.setDate(currentEndDate.getDate() + 21);
            setEndDate(currentEndDate);
        }
        setSearchParams({...searchParams, daysLookingAhead:formatDate(currentEndDate), daysLookingBack:formatDate(new Date())})
    }
    const formatDate = (date) => {
        const formattedDate = date
        ? date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        : "";
        return formattedDate;
    }
    const handleStartDateChange = (date) => {
        setCurrentDateSelection("Custom");
        setStartDate(date);
        setSearchParams({...searchParams, daysLookingBack:formatDate(date)})
    };
    const handleEndDateChange = (date) => {
        setCurrentDateSelection("Custom");
        setEndDate(date);
        setSearchParams({...searchParams, daysLookingAhead:formatDate(date)})
    };
    const startDatepickerRef = useRef(null);
    const openStartDatePicker = () => {
        startDatepickerRef.current.setOpen(true);
    };
    const endDatepickerRef = useRef(null);
    const openEndDatePicker = () => {
        endDatepickerRef.current.setOpen(true);
    };

    return (
        <>
            <div id="apptDate" className="dobrow row" style={{padding:"20px"}}>
                <div className="col-lg-3 col-md-12 datefilterRow">
                    <div className="dateText">Date filter:</div>
                    <select className="datefinInput" id="currentDate" value={currentDateSelection} onChange={handleDateSelectionChange}>
                        <option value="0">Today</option>
                        <option value="7">Next 7 Days</option>
                        <option value="14">Next 14 Days</option>
                        <option value="21">Next 21 Days</option>
                        <option value="Custom">Custom</option>
                    </select>
                </div>
                <div className="col-lg-3 col-md-12 datefilterRow">
                    <div className="dateText">Start Date:</div>
                    <div className='custom-input'>
                        <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="MM/DD/YYYY"
                            className="custom-datepicker"
                            ref={startDatepickerRef}
                        />
                        <FontAwesomeIcon
                            icon={faCalendar}
                            className="calendar-icon"
                            onClick={openStartDatePicker}
                        />
                    </div>
                </div>
                <div className="col-lg-3 col-md-12 datefilterRow" >
                    <div className="dateText">End Date:</div>
                    <div className='custom-input'>
                        <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="MM/DD/YYYY"
                            className="custom-datepicker"
                            ref={endDatepickerRef}
                        />
                        <FontAwesomeIcon
                            icon={faCalendar}
                            className="calendar-icon"
                            onClick={openEndDatePicker}
                        />
                    </div>
                </div>
                <div className=" col-lg-3 col-md-12 d-lg-flex">
                    <div className="visit_History" id="visit_History"onClick={()=>{setShowStatus(true)}}>Status</div>
                    <div className="vist_survey_status" id="vist_survey_status" onClick={()=>{setShowSchedule(true);}}>Schedule</div>
                </div>

            </div>
            {searchResults.length !== 0 && (<VistLookupPatientlist searchResults={searchResults} selectedPatients={props.selectedPatients} setSelectedPatients = {props.setSelectedPatients} addAllPatients={props.addAllPatients} checkAllpatients={props.checkAllpatients} editSelectedPatients={props.editSelectedPatients} checkPatientExists={props.checkPatientExists} />) }
        </>
    )
}

export default AppointmentDateSelection