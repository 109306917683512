const HistoryList = [
    {
        "status_uc_redcap_survey_history": "In Progress",
        "sent_dt_tm_uc_redcap_survey_history": "11/08/2023 00:55",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/08/2023 01:13",
        "sent_email_uc_redcap_survey_history": "spratihary@chla.usc.edu",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Sourajit Pratihary",
        "additional_party_uc_redcap_survey_history": "behavioralhealthopprogram@chla.usc.edu"
    },
    {
        "status_uc_redcap_survey_history": "In Progress",
        "sent_dt_tm_uc_redcap_survey_history": "11/08/2023 00:55",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/08/2023 01:08",
        "sent_email_uc_redcap_survey_history": "spratihary@chla.usc.edu",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Sourajit Pratihary",
        "additional_party_uc_redcap_survey_history": "behavioralhealthopprogram@chla.usc.edu"
    },
    {
        "status_uc_redcap_survey_history": "In Progress",
        "sent_dt_tm_uc_redcap_survey_history": "11/08/2023 00:55",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/08/2023 01:07",
        "sent_email_uc_redcap_survey_history": "spratihary@chla.usc.edu",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Sourajit Pratihary",
        "additional_party_uc_redcap_survey_history": "behavioralhealthopprogram@chla.usc.edu"
    },
    {
        "status_uc_redcap_survey_history": "In Progress",
        "sent_dt_tm_uc_redcap_survey_history": "11/08/2023 00:55",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/08/2023 01:03",
        "sent_email_uc_redcap_survey_history": "spratihary@chla.usc.edu",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Sourajit Pratihary",
        "additional_party_uc_redcap_survey_history": "behavioralhealthopprogram@chla.usc.edu"
    },
    {
        "status_uc_redcap_survey_history": "In Progress",
        "sent_dt_tm_uc_redcap_survey_history": "11/08/2023 00:55",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/08/2023 00:55",
        "sent_email_uc_redcap_survey_history": "spratihary@chla.usc.edu",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Sourajit Pratihary",
        "additional_party_uc_redcap_survey_history": "behavioralhealthopprogram@chla.usc.edu"
    },
    {
        "status_uc_redcap_survey_history": "Sent",
        "sent_dt_tm_uc_redcap_survey_history": "11/08/2023 00:55",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/08/2023 00:55",
        "sent_email_uc_redcap_survey_history": "spratihary@chla.usc.edu",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Sourajit Pratihary",
        "additional_party_uc_redcap_survey_history": "behavioralhealthopprogram@chla.usc.edu"
    },
    {
        "status_uc_redcap_survey_history": "Started",
        "sent_dt_tm_uc_redcap_survey_history": "11/07/2023 23:56",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/07/2023 23:56",
        "sent_email_uc_redcap_survey_history": "",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Sourajit Pratihary",
        "additional_party_uc_redcap_survey_history": ""
    },
    {
        "status_uc_redcap_survey_history": "Started",
        "sent_dt_tm_uc_redcap_survey_history": "11/07/2023 05:00",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/07/2023 05:00",
        "sent_email_uc_redcap_survey_history": "",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Piyush Das",
        "additional_party_uc_redcap_survey_history": ""
    },
    {
        "status_uc_redcap_survey_history": "Started",
        "sent_dt_tm_uc_redcap_survey_history": "11/07/2023 04:57",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/07/2023 04:57",
        "sent_email_uc_redcap_survey_history": "",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Piyush Das",
        "additional_party_uc_redcap_survey_history": ""
    },
    {
        "status_uc_redcap_survey_history": "In Progress",
        "sent_dt_tm_uc_redcap_survey_history": "11/02/2023 03:45",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/02/2023 03:45",
        "sent_email_uc_redcap_survey_history": "paacharya@chla.usc.edu",
        "phone_number_uc_redcap_survey_history": "3233612378",
        "logged_in_user_uc_redcap_survey_history": "Piyush Das",
        "additional_party_uc_redcap_survey_history": "behavioralhealthopprogram@chla.usc.edu"
    },
    {
        "status_uc_redcap_survey_history": "Sent",
        "sent_dt_tm_uc_redcap_survey_history": "11/02/2023 03:45",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/02/2023 03:45",
        "sent_email_uc_redcap_survey_history": "paacharya@chla.usc.edu",
        "phone_number_uc_redcap_survey_history": "3233612378",
        "logged_in_user_uc_redcap_survey_history": "Piyush Das",
        "additional_party_uc_redcap_survey_history": "behavioralhealthopprogram@chla.usc.edu"
    },
    {
        "status_uc_redcap_survey_history": "Started",
        "sent_dt_tm_uc_redcap_survey_history": "11/02/2023 03:06",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/02/2023 03:06",
        "sent_email_uc_redcap_survey_history": "",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Piyush Das",
        "additional_party_uc_redcap_survey_history": ""
    },
    {
        "status_uc_redcap_survey_history": "Started",
        "sent_dt_tm_uc_redcap_survey_history": "11/02/2023 03:04",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/02/2023 03:04",
        "sent_email_uc_redcap_survey_history": "",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Piyush Das",
        "additional_party_uc_redcap_survey_history": ""
    },
    {
        "status_uc_redcap_survey_history": "Started",
        "sent_dt_tm_uc_redcap_survey_history": "11/02/2023 02:31",
        "date_modified_dt_tm_uc_redcap_survey_history": "11/02/2023 02:31",
        "sent_email_uc_redcap_survey_history": "",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Sourajit Pratihary",
        "additional_party_uc_redcap_survey_history": ""
    },
    {
        "status_uc_redcap_survey_history": "In Progress",
        "sent_dt_tm_uc_redcap_survey_history": "10/17/2023 02:43",
        "date_modified_dt_tm_uc_redcap_survey_history": "10/17/2023 02:43",
        "sent_email_uc_redcap_survey_history": "spanda@chla.usc.edu",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Sourav Panda",
        "additional_party_uc_redcap_survey_history": ""
    },
    {
        "status_uc_redcap_survey_history": "Sent",
        "sent_dt_tm_uc_redcap_survey_history": "10/17/2023 02:42",
        "date_modified_dt_tm_uc_redcap_survey_history": "10/17/2023 02:42",
        "sent_email_uc_redcap_survey_history": "spanda@chla.usc.edu",
        "phone_number_uc_redcap_survey_history": "",
        "logged_in_user_uc_redcap_survey_history": "Sourav Panda",
        "additional_party_uc_redcap_survey_history": ""
    }
]

export default HistoryList;