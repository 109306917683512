import React, { useEffect, useState } from 'react'
import HistoryList from './historylist';
import getSurveyHistory from '../Api/getSurveyHistory';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../AppContextProvider';
import secrets from '../../env.json';

export const InstrumentList = (props) => {
    const location = useLocation();
    const { patientData, List, surveyData, selectionType } = location.state;
    const {FormData,getStatus, instrument} = props;
    const [history, setHistory] = useState(false);
    const [loader, setLoader] = useState(false);
    const [surveyHistory, setSurveyHistory] = useState();
    const [statusData, setStatusData] = useState({
        historyStatus: false,
        status: <div></div>
    });
    const {accessToken} = useAuth();

    
    const getSurveyData = (pid, instrumentName) => {
        var requestBody = {
            "recordId" : props.recordId,
            "pid" : pid,
            "instrumentName" : instrumentName
        }
        setLoader(true);
        getSurveyHistory(requestBody,accessToken)
      .then((response) => {
        
        setLoader(false);
        setSurveyHistory(response);
      })
      .catch((err) => {
        setLoader(false);
        
      });
    }
    const checkboxToggle = () =>{
        var isCheckedLocal = props.isSurveySelected(props.data.pid, props.instrument.instrumentName);
        props.alterChosenForms(props.data.pid, instrument.instrumentName, instrument.instrumentLabel, !isCheckedLocal, instrument.surveyType, instrument.surveyId);
    }

    useEffect(() => {
        setStatusData(getStatus(props.FormData, props.instrument, props.data.pid))
        setHistory(false);
        setSurveyHistory()
    }, [FormData])

    return (
        <div className="instruments" style={{fontSize:"18px"}}>
            <div className="row">
                <div className="col-10 d-flex align-items-center">
                    {" "}
                    {props.instrument.instrumentLabel}{" "}
                    {(selectionType !== "encounter" && props.instrument.surveyType !== "") && 
                    <p style={{marginLeft:"5px", fontWeight:"lighter", paddingTop:"0.7rem", fontSize:"1rem"}}>
                    {"(Please select an encounter to send this survey)"}
                    </p>
                    }
                </div>
                <div className="col-2">
                    <label
                        className="custom-checkbox-label"
                        onClick={() => {
                            checkboxToggle();                            
                        }}
                    >
                        {!props.isSurveySelected(props.data.pid, props.instrument.instrumentName) ? (
                            <span>&#9744;</span>
                        ) : (
                            <span>&#9745;</span>
                        )}
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="col-7">
                    {statusData.status}
                </div>
                {statusData.historyStatus &&<div className="col-5 d-flex justify-content-end historyText">
                    <div
                        onClick={() => {
                            setHistory(!history);
                            getSurveyData(props.data.pid, props.instrument.instrumentName);
                        }}
                    >
                        {" "}
                        View History{" "}
                    </div>{" "}
                    <div className="pt-1">{history ? "▼" : "▲"}</div>
                </div>}
            </div>
            {history ? (
                <div className="row HistoryTable ">
                    <div
                        className="row m-0 d-none d-lg-flex justify-content-space-between px-1 py-3 sticky-top "
                        style={{ backgroundColor: "#E9ECEF", zIndex : "0" }}
                    >
                        <div
                            className="col-lg-1 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop ">Status</span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop ">Sent Date</span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop ">
                                Date Modified
                            </span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop ">Email Used</span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop">
                                Phone Number Used
                            </span>
                        </div>
                        <div
                            className="col-lg-1 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop">Sent By</span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left pl-4"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop">
                                Additional Party
                            </span>
                        </div>
                    </div>
                    <HistoryList HistoryData={surveyHistory} key={props.instrument} />
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}