import React, { useState, useEffect } from "react";
import "../../patientsList.css";
import AppointmentListData from "./appointmentData";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

function AppointmentTable(props) {
  const [AppointmentList, setAppointmentList] = useState(props.appointmentList);
  const [locationFilter, setLocationFilter] = useState("All");
  const [typeFilter, setTypeFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");
  const [showLocationSelect, setShowLocationSelect] = useState(false);
  const [showTypeSelect, setShowTypeSelect] = useState(false);
  const [showStatusSelect, setShowStatusSelect] = useState(false);

  useEffect(() => {
    setAppointmentList(props.appointmentList);
  }, [props.appointmentList]);

  const generateFilterOptions = (columnName) => {
    const uniqueValues = [
      "All",
      ...new Set(AppointmentList.map((appointment) => appointment[columnName])),
    ];
    return uniqueValues.filter((value) => value);
  };

  const handleLocationFilterChange = (selectedOption) => {
    setLocationFilter(selectedOption.value);
    toggleLocationSelect();
  };

  const handleTypeFilterChange = (selectedOption) => {
    setTypeFilter(selectedOption.value);
    toggleTypeSelect();
  };

  const handleStatusFilterChange = (selectedOption) => {
    setStatusFilter(selectedOption.value);
    toggleStatusSelect();
  };

  const filteredAppointments = AppointmentList.filter((appointment) => {
    return (
      (locationFilter === "All" ||
        appointment.appt_location_unit === locationFilter) &&
      (typeFilter === "All" || appointment.appt_type === typeFilter) &&
      (statusFilter === "All" || appointment.appt_status === statusFilter)
    );
  });

  const toggleLocationSelect = () => {
    setShowLocationSelect(!showLocationSelect);
  };

  const toggleTypeSelect = () => {
    setShowTypeSelect(!showTypeSelect);
  };

  const toggleStatusSelect = () => {
    setShowStatusSelect(!showStatusSelect);
  };

  return (
    <>
      <div className="row d-flex justify-content-center finlistbody">
        <div
          className="col-lg-12 col-md-12 col-sm-12"
          style={{ margin: "auto" }}
        >
          <div className="prow">
            <div className="row m-0 d-flex justify-content-center p-3 FinDataTop">
              {AppointmentList?.length === 0 ? (
                <div
                  className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center"
                  style={{ color: "black" }}
                >
                  <span className="patientsinfoTop">No Scheduled Appointments</span>
                </div>
              ) : (
                <>
                  <div
                    className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                    style={{ color: "black" }}
                  >
                    <span className="patientsinfoTop">
                    <div className="apptDisplayScreen">Appointment Location</div><div className="apptDisplayMidScreen">Appt Location</div>
                      <FontAwesomeIcon
                        icon={faFilter}
                        onClick={toggleLocationSelect}
                        className="filter-icon"
                      />
                      {showLocationSelect && (
                        <div className="popup-select">
                          <Select
                            value={{
                              value: locationFilter,
                              label: locationFilter,
                            }}
                            onChange={handleLocationFilterChange}
                            options={generateFilterOptions(
                              "appt_location_unit"
                            ).map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            isSearchable
                            placeholder="Select location"
                          />
                        </div>
                      )}
                    </span>
                  </div>
                  <div
                    className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                    style={{ color: "black" }}
                  >
                    <span className="patientsinfoTop">
                      <div className="apptDisplayScreen">Appointment Type</div><div className="apptDisplayMidScreen">Appt Type</div>
                      <FontAwesomeIcon
                        icon={faFilter}
                        onClick={toggleTypeSelect}
                        className="filter-icon"
                      />
                      {showTypeSelect && (
                        <div className="popup-select">
                          <Select
                            value={{ value: typeFilter, label: typeFilter }}
                            onChange={handleTypeFilterChange}
                            options={generateFilterOptions("appt_type").map(
                              (option) => ({
                                value: option,
                                label: option,
                              })
                            )}
                            isSearchable
                            placeholder="Select type"
                          />
                        </div>
                      )}
                    </span>
                  </div>
                  <div
                    className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                    style={{ color: "black", whiteSpace:"normal" }}
                  >
                    <span className="patientsinfoTop">
                    <div className="apptDisplayScreen">Appointment Status</div><div className="apptDisplayMidScreen">Appt Status</div>
                      <FontAwesomeIcon
                        icon={faFilter}
                        onClick={toggleStatusSelect}
                        className="filter-icon"
                      />
                      {showStatusSelect && (
                        <div className="popup-select">
                          <Select
                            value={{ value: statusFilter, label: statusFilter }}
                            onChange={handleStatusFilterChange}
                            options={generateFilterOptions("appt_status").map(
                              (option) => ({
                                value: option,
                                label: option,
                              })
                            )}
                            isSearchable
                            placeholder="Select status"
                          />
                        </div>
                      )}
                    </span>
                  </div>
                  <div
                    className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                    style={{ color: "black" }}
                  >
                    <span className="patientsinfoTop">Medical Service</span>
                  </div>
                  <div
                    className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                    style={{ color: "black" }}
                  >
                    <span className="patientsinfoTop"><div className="apptDisplayScreen">Appointment </div><div className="apptDisplayMidScreen">Appt</div>&nbsp; Date</span>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-4 d-flex justify-content-left"></div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {filteredAppointments?.length !== 0 ? (
        filteredAppointments?.map((Appointment, i) => {
          return (
            <AppointmentListData
              List={props.List}
              patientData={props.patientData}
              Appointment={Appointment}
              key={i}
            />
          );
        })
      ):
      <>
      {AppointmentList.length !== 0 && 
      <div className="row d-flex justify-content-center p-3 FinDataTop">
        <div
          className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center"
          style={{ color: "black" }}
        >
          <span className="patientsinfoTop" >No Appointments found for the current selection</span>
        </div>
      </div>
      }
      </>}
    </>
  );
}
export default AppointmentTable;
