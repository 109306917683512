import React, { useState } from 'react'
import "./VisitList.css";

const ConfirmScheduleModal = (props) => {
    const {onClickSendSurvey, setShowScheduleConfirmation} = props;
    const [dateTime, setDateTime] = useState(new Date());
    const onTimeChange = (e) => {
        setDateTime(e.target.value);
    }
    const onClickSubmit = () => {
        onClickSendSurvey(dateTime);
        setShowScheduleConfirmation(false);
    }
    const onClickCancel = () => {
        setShowScheduleConfirmation(false);
    }
    return (
        <div id="overlay2">
            <div id="confirmShedule" className="popupWindows" style={{borderRadius:"20px"}}>
                <div className="row">
                    <div className="col-12 justify-content-center align-items-center">
                        <label style={{ width: "100%", textAlign: "left", marginTop: "0.5rem" }}>Schedule Date and Time</label>
                        <input style={{ border: "1px solid #ced4da", borderRadius: "8px", overflow: "hidden", width: "80%", margin: "10px", marginBottom: "30px", padding: "10px" }} placeholder="Select date and time" type="datetime-local" id="scheduledate" autocomplete="off" onChange={onTimeChange} />
                        <div className="row">
                            <div className="col-6 d-flex justify-content-end">
                                <div className="submit_button-pin" id="submitschedulePopup" style={{ backgroundColor: "#004879" }} onClick={onClickSubmit}>
                                    <div >Submit</div>
                                </div>
                            </div>
                            <div className="col-6 d-flex justify-content-start">
                                <div className="submit_button-pin" id="submitscheduleCancel" onClick={onClickCancel}>
                                    <div>Cancel</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmScheduleModal