import React, { useEffect } from 'react';
import "./VisitList.css";

const PreferredContactModal = (props) => {
    const inputHandler = (e) => {
        props.setPopUpDetails({...props.popUpDetails, [e.target.id]: e.target.value})
    }
    useEffect(() => {
        
    },[props.popUpDetails])
  return (
    <div id="overlay2">
    <div id="contactPopup" style={{borderRadius:"20px"}}>
        <div class = "row">
            <div class ="col-12 justify-content-center align-items-center">
                <p class = "m-0" style = {{color:"black", textAlign: "left"}}>
                    Patient contact information is from STAR. To permanently update the contact information for this patient, please do so in STAR
                </p>
                <label style = {{width:"100%", textAlign:"left", marginTop: "0.5rem"}}>
                    Preferred Contact
                </label>
                <select class = "finInput" id="preferredContact" onChange={inputHandler} value ={props.popUpDetails.preferredContact}>
                    <option value="Text">Phone</option>
                    <option value="Email">Email</option>
                </select>
                <label style = {{width:"100%", textAlign:"left", marginTop: "0.5rem"}}>Email Address</label>
                <input id="email" onChange={inputHandler} value = {props.popUpDetails.email} class = "finInput" type="text" name="fname"/>
                <label style = {{width:"100%", textAlign:"left", marginTop: "0.5rem"}}>Phone Number</label>
                <input id="phone" onChange={inputHandler} value = {props.popUpDetails.phone}  class = "finInput" type="text" name="fname"/>
            </div>
        </div>
        <div class ="row" style={{marginTop:"30px"}}>
            <div class="col-6 d-flex justify-content-end">
                <div class="submit_button-pin" onClick={props.onSave} style={{backgroundColor : "#004879"}}>
                    <div>Submit</div>
                </div>
            </div>
            <div class="col-6 d-flex justify-content-start">
                <div class="submit_button-pin" onClick={props.onCancel}>
                    <div>Cancel</div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default PreferredContactModal