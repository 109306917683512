import React, { useEffect, useState } from "react";
import VisitLookupPatients from "./VisitLookupPatients";

const VistLookupPatientlist = (props) => {
    const {searchResults,selectedPatients,addAllPatients,checkAllpatients, setSelectedPatients} = props;
    const [globalCheck,setGlobalCheck] = useState(false);
    const [sortType, setSortType] = useState("appt_start_dt_tm");
    const handleMainCheckChange = () =>{  
        addAllPatients();
    }
    useEffect(()=>{
        setGlobalCheck(checkAllpatients());
    },[selectedPatients])

    const [sortConfigTime, setSortConfigTime] = useState({
      column: "appt_start_dt_tm",
      order: "asc",
    });

    const [sortConfigName, setSortConfigName] = useState({
      column : "patient_name",
      order : "asc"
    })

    const [sortConfigResource, setSortConfigResource] = useState({
      column : "resource",
      order : "asc"
    })

    const [sortConfigApptType, setSortConfigApptType] = useState({
      column : "appt_type",
      order : "asc"
    })

    const [sortConfigApptStatus, setSortConfigApptStatus] = useState({
      column : "appt_status",
      order : "asc"
    })

    const getSortIcon = (column, config) => {
      if (config.column === column) {
        return config.order === "asc" ? "▼" : "▲";
      }
      return "";
    };

    const handleSortFields = (currentSortType) => {
      setSortType(currentSortType);
      if(currentSortType === "appt_start_dt_tm"){     
        setSortConfigTime({
          column: "appt_start_dt_tm",
          order: sortConfigTime.order === "asc" ? "desc" : "asc",
        });
      }
      else if(currentSortType === "patient_name"){
        setSortConfigName({
          column: "patient_name",
          order: sortConfigName.order === "asc" ? "desc" : "asc",
        });
      }
      else if(currentSortType === "resource"){
        setSortConfigResource({
          column: "resource",
          order: sortConfigResource.order === "asc" ? "desc" : "asc",
        })
      }
      else if(currentSortType === "appt_type"){
        setSortConfigApptType({
          column: "appt_type",
          order: sortConfigApptType.order === "asc" ? "desc" : "asc",
        })
      }
      else if(currentSortType === "appt_status"){
        setSortConfigApptStatus({
          column: "appt_status",
          order: sortConfigApptStatus.order === "asc" ? "desc" : "asc",
        })
      }
    };

    const sortedPatientList = () => {
      var config= {};
      if(sortType === "appt_start_dt_tm"){
        config = sortConfigTime;
      }
      else if(sortType === "patient_name"){
        config = sortConfigName;
      }
      else if(sortType === "resource"){
        config = sortConfigResource;
      }
      else if(sortType === "appt_type"){
        config = sortConfigApptType;
      }
      else if(sortType === "appt_status"){
        config = sortConfigApptStatus;
      }
      return [...searchResults].sort((a, b) => {
        var columnA = "";
        var columnB = "";
        if(sortType === "appt_start_dt_tm" || sortType === "resource" || sortType === "appt_type" || sortType === "appt_status"){
          columnA = (a["appointment"][config.column]) || "";
          columnB = ( b["appointment"][config.column]) || "";
        }
        else{
          columnA = (a[config.column]) || "";
          columnB = ( b[config.column]) || "";
        }
        if (config.order === "asc") {
          return columnA.localeCompare(columnB);
        } else {
          return columnB.localeCompare(columnA);
        }
      });
    };
    
  return (
    <div>
      <form className="filter-content">
        <fieldset className="filter">
          <div className="d-none d-lg-block">
            <div className="row m-0 justify-content-center align-item-center p-3 patientDataTop">
              <div className="custom-lg-1 customCheckboxColumn col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span class="d-flex" />{" "}
                <input
                  type="checkbox"
                  className="PatientCheckbox"
                  id="PatientCheckbox"
                  name="Locked"
                  style={{ height: "25px", width: "25px" }}
                  onClick={handleMainCheckChange}
                  checked={globalCheck}
                />{" "}
              </div>
              <div className="custom-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop" onClick={() => handleSortFields("appt_start_dt_tm")} style={{cursor:"pointer"}}>
                  {" "}
                  Time{" "}
                  <span
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      color: "#004879",
                    }}
                    id="visit_Time${t_Id}"
                    className="Des des"
                  >
                  {getSortIcon("appt_start_dt_tm", sortConfigTime)}
                  </span>{" "}
                </span>
              </div>
              <div className="custom-lg-2 customPatientColumn col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop"onClick={() => handleSortFields("patient_name")} style={{cursor:"pointer"}}>
                  Patient{" "}
                  <span
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      color: "#004879",
                      cursor:"pointer"
                    }}
                    id="visit_Name${n_Id}"
                    className="Des des"
                  >
                    {getSortIcon("patient_name", sortConfigName)}
                  </span>
                </span>
              </div>
              <div className="custom-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop">AGE</span>
              </div>
              <div className="custom-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop" onClick={() => handleSortFields("resource")} style={{cursor:"pointer"}}>
                  Resource{" "}
                  <span
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      color: "#004879",
                    }}
                    id="visit_Resource${r_Id}"
                    className="Des des"
                  >
                    {getSortIcon("resource", sortConfigResource)}
                  </span>
                </span>
              </div>
              <div className="custom-lg-2 customDetailsColumn col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop" onClick={() => handleSortFields("appt_type")} style={{cursor:"pointer"}}>
                  Details{" "}
                  <span
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      color: "#004879",
                    }}
                    id="visit_Resource${r_Id}"
                    className="Des des"
                  >
                    {getSortIcon("appt_type", sortConfigApptType)}
                  </span>
                </span>
              </div>
              <div className="custom-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop" onClick={() => handleSortFields("appt_status")} style={{cursor:"pointer"}}>
                  Status{" "}
                  <span
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      color: "#004879",
                    }}
                    id="visit_Resource${r_Id}"
                    className="Des des"
                  >
                    {getSortIcon("appt_status", sortConfigApptStatus)}
                  </span>
                </span>
              </div>
              <div className="custom-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop">Contacts</span>
              </div>
            </div>
          </div>
          <div className="visit_sorted">
          {sortedPatientList()
            .length === 0 ? (
            <p>No Patients Found</p>
          ) : (
            sortedPatientList().map(
              (searchResult, i) => {
                return <VisitLookupPatients patientsDetail={searchResult} selectedPatients={props.selectedPatients} editSelectedPatients={props.editSelectedPatients} checkPatientExists={props.checkPatientExists} setSelectedPatients={setSelectedPatients}/>
              }
            )
          )}
          </div>
        </fieldset>
      </form>
    </div>
  );
}
export default VistLookupPatientlist;
