import React from 'react';
import "../patients.css";

const EnvConfirmationDialog = (props) => {
    
    return (
        <div id="overlay2">
            <div id="envConfirmation">
                <div class="row">
                    <div class="d-flex col-12 justify-content-center align-items-center">
                        <span style={{ fontSize: "large", fontWeight: "bold", marginLeft: "5px", color:"black" }}>
                            Are you sure you want to change cerner domain ?
                        </span>
                    </div>
                </div>
                <div class="row" style={{ marginTop: "30px" }}>
                    <div class="col-6 d-flex justify-content-end">
                        <div class="submit_button-pin" style={{ backgroundColor: "#004879", width:"5rem" }} onClick={() => props.onConfirmation(true)}>
                            <div >Yes</div>
                        </div>
                    </div>
                    <div class="col-6 d-flex justify-content-left">
                        <div class="submit_button-pin" style={{ backgroundColor: "#004879", width:"5rem" }} onClick={() => props.onConfirmation(false)}>
                            <div >No</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnvConfirmationDialog