export const getAge = (data) => {
    if (data === undefined) {
      return { date: "", age: "" };
    }

    const birthMonth = data.slice(0, 2);
    const birthDay = data.slice(2, 4);
    const birthYear = data.slice(4, 8);

    const today = new Date();
    const birthDate = new Date(`${birthYear}-${birthMonth}-${birthDay}`);

    const ageInMilliseconds = today - birthDate;
    const ageInYears = Math.floor(ageInMilliseconds / 31536000000); // Approximate milliseconds in a year

    return  ageInYears
  };