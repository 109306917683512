import React, { useState, useRef, useEffect } from "react";
import "../patients.css";
import { useOktaAuth } from "@okta/okta-react";
import { useLocation, useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FormData from "../Api/FormData.js";
import Chevron from "../images/chevron.png";
import dChevron from "../images/chevron_d.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HistoryList from "./historylist.js";
import HistoryData from "../Api/HistoryData.js";
import {
  faCheckCircle,
  faShareSquare,
  faPaperPlane,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { InstrumentList } from "./instrumentList.js";

function Instruments(props) {
  const [Ichevron, setIchevron] = useState(false);
  //const [isChecked, setIsChecked] = useState(false);

  // const handleCheckboxChange = (instrument) => {
  //   setIsChecked(!isChecked);
  //   if (!isChecked) {
  //     props.setSelectedInstruments([...props.selectedInstruments, instrument]);
  //   } else {
  //     const updatedInstruments = props.selectedInstruments.filter(
  //       (element) => element.instrumentName !== instrument.instrumentName
  //     );

  //     // Update the state with the new array
  //     props.setSelectedInstruments(updatedInstruments);
  //   }
  // };

  const createDateString = (dateString) => {
    const date1 = new Date(
      new Date(dateString).toLocaleString("en-US", {
        timeZone: "America/Los_Angeles",
      })
    );
    const date2 = new Date(
      new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
    );
    const diffTime = Math.abs(date2 - date1);
    const diffSeconds = Math.floor(diffTime / 1000);
    const diffMins = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffMonths / 12);
    if (diffYears == 0) {
      if (diffMonths == 0) {
        if (diffDays == 0) {
          if (diffHours == 0) {
            if (diffMins == 0) {
              return " just now";
            } else {
              if (diffMins == 1) {
                return " " + diffMins + " minute ago";
              } else {
                return " " + diffMins + " minutes ago";
              }
            }
          } else {
            if (diffHours == 1) {
              return " " + diffHours + " hour ago";
            } else {
              return " " + diffHours + " hours ago";
            }
          }
        } else {
          if (diffDays == 1) return " " + diffDays + " day ago";
          else return " " + diffDays + " days ago";
        }
      } else {
        if (diffMonths == 1) return " " + diffMonths + " month ago";
        else return " " + diffMonths + " months ago";
      }
    } else {
      if (diffYears == 1) return " " + diffYears + "year ago";
      else return " " + diffYears + "years ago";
    }
  };

  const getStatus = (Projects, Instument, id) => {
    if (Projects.forms != null && Projects.forms.length > 0) {
      const Formsresult = Projects.forms.find(
        (forms) => forms.pid === props.data.pid
      );
      if (Formsresult !== undefined) {
        const instrumentResult = Formsresult.instruments.find(
          (data) => data.formName === Instument.instrumentName
        );
        if (
          instrumentResult !== undefined &&
          instrumentResult["status"] === 1
        ) {
          return {
              historyStatus: true,
              status: <div>
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "20px", color: "green" }}
              />
              &nbsp;
              <span className="lastDate">
                Completed on&nbsp;{instrumentResult["lastCompleted"]}&nbsp;({" "}
                {createDateString(instrumentResult["lastCompleted"])} )
              </span>
            </div>
            }
          ;
        } else {
          if (
            instrumentResult !== undefined &&
            instrumentResult["sentDate"] === ""
          ) {
            return {
                historyStatus: true,
                status: <div>
                <FontAwesomeIcon
                  icon={faShareSquare}
                  style={{ fontSize: "18px", color: "#FF7F00" }}
                />
                &nbsp;
                <span className="lastDate">Sent</span>
              </div>
              }
            ;
          } else {
            if (Projects.formsStatus && Projects.formsStatus.length > 0) {
              const FormsStatusresult = Projects.formsStatus.find(
                (formStatus) => formStatus.pid === props.data.pid
              );
              if (FormsStatusresult !== undefined) {
                const instrumentResult = FormsStatusresult.instruments.find(
                  (data) => data.formName === Instument.instrumentName
                );
                if (
                  instrumentResult !== undefined &&
                  instrumentResult["status"] === "Completed"
                ) {
                  return {
                      historyStatus: true,
                      status: <div>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ fontSize: "20px", color: "green" }}
                      />
                      &nbsp;
                      <span className="lastDate">
                        Completed on&nbsp;{instrumentResult["lastCompleted"]}
                        &nbsp;({" "}
                        {createDateString(instrumentResult["lastCompleted"])} )
                      </span>
                    </div>
                    }
                  ;
                } else if (
                  instrumentResult !== undefined &&
                  instrumentResult["sentDate"] !== ""
                ) {
                  return {
                      historyStatus: true,
                      status: <div>
                      <FontAwesomeIcon
                        icon={faShareSquare}
                        style={{ fontSize: "18px", color: "#FF7F00" }}
                      />
                      &nbsp;
                      <span className="lastDate">
                        Sent&nbsp;{instrumentResult["sentDate"]}&nbsp;({" "}
                        {createDateString(instrumentResult["sentDate"])} )
                      </span>
                    </div>
                    }
                  ;
                } else {
                  
                  return {
                    historyStatus: false,
                    status: <div>
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      style={{ fontSize: "15px", color: "#4DC1EF" }}
                    />
                    &nbsp;
                    <span className="lastDate">Not Sent</span>
                  </div>
                  }
                  ;
                }
              }
            }
            
            return {
              historyStatus: false,
              status: <div>
              <FontAwesomeIcon
                icon={faPaperPlane}
                style={{ fontSize: "15px", color: "#4DC1EF" }}
              />
              &nbsp;
              <span className="lastDate">Not Sent</span>
            </div>
            }
            ;
          }
        }
      } else {
        if (Projects.formsStatus != null && Projects.formsStatus.length > 0) {
          const FormsStatusresult = Projects.formsStatus.find(
            (formStatus) => formStatus.pid === props.data.pid
          );
          if (FormsStatusresult !== undefined) {
            const instrumentResult = FormsStatusresult.instruments.find(
              (data) => data.formName === Instument.instrumentName
            );
            if (
              instrumentResult !== undefined &&
              instrumentResult["status"] === "Completed"
            ) {
              return {
                  historyStatus: true,
                  status: <div>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ fontSize: "20px", color: "green" }}
                  />
                  &nbsp;
                  <span className="lastDate">
                    Completed on&nbsp;{instrumentResult["lastCompleted"]}
                    &nbsp;({" "}
                    {createDateString(instrumentResult["lastCompleted"])} )
                  </span>
                </div>
                }
              ;
            } else {
              if (
                instrumentResult !== undefined &&
                instrumentResult["sentDate"] === ""
              ) {
                return {
                    historyStatus: true,
                    status: <div>
                    <FontAwesomeIcon
                      icon={faShareSquare}
                      style={{ fontSize: "18px", color: "#FF7F00" }}
                    />
                    &nbsp;
                    <span className="lastDate">Sent</span>
                  </div>
                  }
                ;
              } else {
                if (instrumentResult === undefined) {
                  
                  return {
                    historyStatus: false,
                    status: <div>
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      style={{ fontSize: "15px", color: "#4DC1EF" }}
                    />
                    &nbsp;
                    <span className="lastDate">Not Sent</span>
                  </div>
                  }
                  ;
                } else {
                  return {
                      historyStatus: true,
                      status: <div>
                      <FontAwesomeIcon
                        icon={faShareSquare}
                        style={{ fontSize: "18px", color: "#FF7F00" }}
                      />
                      &nbsp;
                      <span className="lastDate">
                        Sent&nbsp;{instrumentResult["sentDate"]}&nbsp;({" "}
                        {createDateString(instrumentResult["sentDate"])} )
                      </span>
                    </div>
                    }
                  ;
                }
              }
            }
          } else {
            
            return {
              historyStatus: false,
              status: <div>
              <FontAwesomeIcon
                icon={faPaperPlane}
                style={{ fontSize: "15px", color: "#4DC1EF" }}
              />
              &nbsp;
              <span className="lastDate">Not Sent</span>
            </div>
            }
            ;
          }
        } else {
          
          return {
            historyStatus: false,
            status: <div>
            <FontAwesomeIcon
              icon={faPaperPlane}
              style={{ fontSize: "15px", color: "#4DC1EF" }}
            />
            &nbsp;
            <span className="lastDate">Not Sent</span>
          </div>
          }
          ;
        }
      }
    } else {
      if (Projects.formsStatus != null) {
        const FormsStatusresult = Projects.formsStatus.find(
          (formStatus) => formStatus.pid === props.data.pid
        );
        if (FormsStatusresult !== undefined) {
          const instrumentResult = FormsStatusresult.instruments.find(
            (data) => data.formName === Instument.instrumentName
          );
          if (
            instrumentResult !== undefined &&
            instrumentResult["status"] === "Completed"
          ) {
            return {
                historyStatus: true,
                status: <div>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ fontSize: "20px", color: "green" }}
                />
                &nbsp;
                <span className="lastDate">
                  Completed on&nbsp;{instrumentResult["lastCompleted"]}&nbsp;({" "}
                  {createDateString(instrumentResult["lastCompleted"])} )
                </span>
              </div>
              }
              
            ;
          } else if (
            instrumentResult !== undefined &&
            instrumentResult["sentDate"] !== ""
          ) {
            return {
              historyStatus: true,
              status: <div>
              <FontAwesomeIcon
                icon={faShareSquare}
                style={{ fontSize: "18px", color: "#FF7F00" }}
              />
              &nbsp;
              <span className="lastDate">
                Sent&nbsp;{instrumentResult["sentDate"]}&nbsp;({" "}
                {createDateString(instrumentResult["sentDate"])} )
              </span>
            </div>
            }
            ;
          } else {
            
            return {
              historyStatus: false,
              status: <div>
              <FontAwesomeIcon
                icon={faPaperPlane}
                style={{ fontSize: "15px", color: "#4DC1EF" }}
              />
              &nbsp;
              <span className="lastDate">Not Sent</span>
            </div>
            }
            ;
          }
        } else {
          
          return {
            historyStatus: false,
            status: <div>
            <FontAwesomeIcon
              icon={faPaperPlane}
              style={{ fontSize: "15px", color: "#4DC1EF" }}
            />
            &nbsp;
            <span className="lastDate">Not Sent</span>
          </div>
          }
          ;
        }
      } else {
        return {
          historyStatus: false,
          status: <div>
          <FontAwesomeIcon
            icon={faPaperPlane}
            style={{ fontSize: "15px", color: "#4DC1EF" }}
          />
          &nbsp;
          <span className="lastDate">Not Sent</span>
        </div>
        }
        ;
      }
    }
  };
  return (
    <>
      {" "}
      <div
        className="formName"
        onClick={() => {
          setIchevron(!Ichevron);
        }}
      >
        <div>{props.data.name}</div>
        {Ichevron ? (
          <img className="chevron" src={dChevron} />
        ) : (
          <img className="chevron" src={Chevron} />
        )}
      </div>
      {Ichevron ? (
        props.data.instruments.map((instrument) => {
          return (
            <>
              {instrument.instrumentLabel == "Patient Experience" ? (
                <></>
              ) : (
                <InstrumentList
                  FormData={props.FormData}
                  instrument={instrument}
                  data={props.data}
                  HistoryData={HistoryData}
                  // isChecked = {isChecked}
                  // handleCheckboxChange={handleCheckboxChange}
                  recordId={props.recordId}
                  alterChosenForms={props.alterChosenForms}
                  isSurveySelected={props.isSurveySelected}
                  getStatus={getStatus}
                  chosenForms={props.chosenForms}
                />
              )}
            </>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
}
export default Instruments;
