import React, { useState, useEffect } from "react";
import "../../patientsList.css";
import EncounterListData from "./encounterData";

function EncounterTable(props) {
  const [patientData, setpatientData] = useState(props.patientData);
  const [encounterList, setEncounterList] = useState(props.EncounterList);
  const [show, setShow] = useState("reg");
  const [sortConfigReg, setSortConfigReg] = useState({
    column: "registration_date",
    order: "desc",
  });
  const [sortConfigDis, setSortConfigDis] = useState({
    column: "discharge_dt_tm",
    order: "asc",
  });

  useEffect(() => {
    if(props.EncounterList){
      
      var flag = props.encounterType;
      var newArr = props.EncounterList;
      if(flag === "inPatient"){
        newArr = props.EncounterList.filter(
          (item) => item.location_facility === "CHLA" 
        );
      }
      else if(flag === "outPatient"){
        newArr = props.EncounterList.filter(
          (item) => item.location_facility !== "CHLA" 
        );
      }
      setEncounterList(newArr);
    }
  }, [props.EncounterList]);

  const handleSortReg = () => {
    
    if (sortConfigReg.column === "registration_date") {
      setSortConfigReg({
        column: "registration_date",
        order: sortConfigReg.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortConfigReg({
        column: "registration_date",
        order: "asc",
      });
    }
    setShow("reg");
  };

  const handleSortDis = () => {
    if (sortConfigDis.column === "discharge_dt_tm") {
      setSortConfigDis({
        column: "discharge_dt_tm",
        order: sortConfigDis.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortConfigDis({
        column: "discharge_dt_tm",
        order: "asc",
      });
    }
    setShow("des");
  };

  const getSortIcon = (column, config) => {
    if (config.column === column) {
      return config.order === "asc" ? "▲" : "▼";
    }
    return "";
  };
  const reformatDateString = (dateString) => {
    // Assuming dateString is in 'mmddyyyy hhmmss' format
    const month = dateString.substring(0, 2);
    const day = dateString.substring(2, 4);
    const year = dateString.substring(4, 8);
    const time = dateString.substring(9); // 'hhmmss'

    return `${year}${month}${day}${time}`; // 'yyyymmddhhmmss'
  };

  const sortedEncounterList = (config) => {
    const { column, order } = config;
    return [...encounterList].sort((a, b) => {
      const columnA = reformatDateString(a[column]) || "";
      const columnB = reformatDateString( b[column]) || "";
      
      if (order === "asc") {
        return columnA.localeCompare(columnB);
      } else {
        return columnB.localeCompare(columnA);
      }
    });
  };

  return (
    <>
      {encounterList && encounterList.length !== 0 && 
        <div className="row d-flex justify-content-center finlistbody">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="prow d-none d-lg-block">
            <div className="row m-0 d-flex justify-content-center p-3 FinDataTop">
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left">
                <span className="patientsinfoTop">FIN</span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left">
                <span className="patientsinfoTop" onClick={handleSortReg} style={{cursor:"pointer"}}>
                  REG {getSortIcon("registration_date", sortConfigReg)}
                </span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left">
                <span className="patientsinfoTop" onClick={handleSortDis} style={{cursor:"pointer"}}>
                  DIS {getSortIcon("discharge_dt_tm", sortConfigDis)}
                </span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left">
                <span className="patientsinfoTop Encountertype text-truncate" style={{whiteSpace: "normal"}}>
                  MEDICAL SERVICE
                </span>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 d-flex justify-content-left"></div>
              <div className="col-lg-2 col-md-4 col-sm-4 d-flex justify-content-left"></div>
            </div>
          </div>
        </div>
      </div>
      }

      {sortedEncounterList(show === "des" ? sortConfigDis : sortConfigReg)
        .length === 0 ? (
          <>
            {props.encountersLoader ? 
                <div className="col-lg-12">
                  <div id="" className="spinner" style={{ marginTop: "40px" }}>
                    <div className="bounce1" style={{ backgroundColor: "#015a96" }}></div>
                    <div className="bounce2" style={{ backgroundColor: "#015a96" }}></div>
                    <div className="bounce3" style={{ backgroundColor: "#015a96" }}></div>
                  </div>
                </div>
                :
                <div className="row m-0 d-flex justify-content-center p-3 FinDataTop">
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center"
                    style={{ color: "black" }}
                  >
                    <span className="patientsinfoTop">No Encounters Found</span>
                  </div>
                </div>
               
              }
          </>
      ) : (
        sortedEncounterList(show === "des" ? sortConfigDis : sortConfigReg).map(
          (Encounter, i) => {
            return <EncounterListData List = {props.List} patientData = {patientData} Encounter={Encounter} key={i} isEncounterList = {props.isEncounterList} setShowEncounterList={props.setShowEncounterList} setDetailsLoader={props.setDetailsLoader} alterEncounterSurvey={props.alterEncounterSurvey}/>;
          }
        )
      )}
    </>
  );
}

export default EncounterTable;
