import React, { useEffect, useState } from "react";
import getSchedule from "../Api/getSchedule";
import { useAuth } from "../AppContextProvider";

const ScheduleAppointment = (props) => {
    const {setShowSchedule} = props
    const {accessToken, userInfo} = useAuth();
    const [scheduleData, setScheduleData] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(()=> {
        setLoader(true);
        getSchedule(accessToken, userInfo.email)
        .then((response) => {
            setScheduleData(response);
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
          });
    },[]);
  return (
    <>
    {loader ? 
    <div id="overlay">
      <div id="loading" className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </div> 
    : 
    <div id="vistCronStatus">
      <div className="row patientwithforms d-flex">
        <div className="col-lg-10 col-sm-12 d-flex">
          <div style={{ color: "#004879", marginRight: "10px" }}>
            {scheduleData.length} Recipients
          </div>
        </div>
        <div id="previousscreen" className="col-lg-2 col-sm-12" onClick={()=>{setShowSchedule(false);}}>
          Previous Screen
        </div>
      </div>
      <div className="d-none d-lg-block">
          <div
            className="row justify-content-center align-item-center p-3 visitpatientDataTop"
          >
            <div className="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
              <span className="patientsinfoTop"> MRN </span>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
              <span className="patientsinfoTop"> Status </span>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
              <span className="patientsinfoTop"> Scheduled Date </span>
            </div>
          </div>
        </div>
        {scheduleData.map((schedule) => (<div
          className="row d-flex justify-content-center  align-item-center p-3 patientData"
          style={{ marginLeft: "15px", marginRight: "15px" }}
        >
          <div className="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none" style={{ color: "black" }}>
              MRN: &nbsp;
            </span>
            <span className="patientsinfo">
              {schedule.mrn}
            </span>
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none" style={{ color: "black" }}>
              Status: &nbsp;
            </span>
            <span className="patientsinfo">
              {schedule.status}
            </span>
          </div>

          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none" style={{ color: "black" }}>
              Scheduled Date Time: &nbsp;
            </span>
            <span className="patientsinfo">
              {schedule.scheduledDatetime}
            </span>
          </div>
        </div>))}
        
    </div>}

    
    </>
    
  );
};

export default ScheduleAppointment;
