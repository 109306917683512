import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  faCheckCircle,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../AppContextProvider";

const VisitStatus = (props) => {
  const { setVisitStatus,surveyList, setShowStatus, setSurveyName} = props;
  const {accessToken,userInfo} = useAuth();

  useEffect(() => {
    const surveySearchParam = {
      loggedInUsername: userInfo?.email,
      instrumentName: "",
      status: "",
      mrn: "",
      firstName: "",
      lastName: "",
    };
    setVisitStatus(surveySearchParam);
  }, []);
  const convertDateTime = (dateTimeString) => {
    // const formattedDateTime = new Date(dateTimeString).toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
    const dateTime = new Date(dateTimeString);
    const formattedDateTime = `${
      dateTime.getMonth() + 1
    }/${dateTime.getDate()}/${dateTime.getFullYear()} ${dateTime.toLocaleString(
      "en-US",
      { hour: "2-digit", minute: "2-digit", hour12: true }
    )}`;
    return formattedDateTime;
  };
  return (
    <>
     {surveyList.lenght !== 0 && (<div id="vistCronStatus">
      <div className="row patientwithforms d-flex">
        <div className="col-lg-10 col-sm-12 d-flex">
          <div style={{ color: "#004879", marginRight: "10px" }}>
            {surveyList.length} Recipients
          </div>
        </div>
        <div
          id="previousscreen"
          className="col-lg-2 col-sm-12"
          onClick={() => {
            setShowStatus(false);
          }}
        >
          Previous Screen
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div className="row justify-content-center align-item-center p-3 visitpatientDataTop">
          <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="patientsinfoTop"> Patients </span>
          </div>
          <div className="col-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="patientsinfoTop"> MRN </span>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="patientsinfoTop"> Surveys </span>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="patientsinfoTop"> Insurance </span>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="patientsinfoTop"> Status </span>
          </div>
        </div>
      </div>
      {surveyList.map((status) => (
        <div
          className="row d-flex justify-content-center  align-item-center p-3 patientData"
          style={{ marginLeft: "15px", marginRight: "15px" }}
        >
          <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none" style={{ color: "black" }}>
              Patients: &nbsp;
            </span>
            <span className="patientsinfo apEmail">
              {status.patient.firstNameUcRedcapPatient}&nbsp;
              {status.patient.lastNameUcRedcapPatient}
            </span>
          </div>
          <div className="col-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none" style={{ color: "black" }}>
              MRN: &nbsp;
            </span>
            <span className="patientsinfo">
              {status.patient.mrnUcRedcapPatient}
            </span>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none" style={{ color: "black" }}>
              Surveys: &nbsp;
            </span>
            <span className="patientsinfo"> {setSurveyName(status.surveyHistory.instrumentNameUcRedcapSurveyHistory)} </span>
          </div>

          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none" style={{ color: "black" }}>
              Insurance: &nbsp;
            </span>
            <span className="patientsinfo">
              {status.patient.insuranceUcRedcapPatient}
            </span>
          </div>

          <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none" style={{ color: "black" }}>
              Status: &nbsp;
            </span>
            {status.surveyHistory.statusUcRedcapSurveyHistory !==
            "Completed" ? (
              <FontAwesomeIcon
                icon={faShareSquare}
                style={{ fontSize: "18px", color: "#FF7F00" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "20px", color: "green" }}
              />
            )}
            <span className="patientsinfo">
              &nbsp;{status.surveyHistory.statusUcRedcapSurveyHistory} On{" "}
              {convertDateTime(
                status.surveyHistory.dateModifiedDtTmUcRedcapSurveyHistory
              )}
            </span>
          </div>
        </div>
      ))}
    </div>)}
    </>
   
    
  );
};

export default VisitStatus;
