import React, { useEffect, useState } from "react";
import Navbar from "./Nav/Navbar";
import { useAuth } from "./AppContextProvider";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import VisitList from "./VisitsList/VisitList";
import getClinic from "./Api/getClinic";
import AppointmentDateSelection from "./VisitsList/AppointmentDateSelection";
import visitLookup from "./Api/visitLookup";
import VisitsSurveyList from "./VisitsList/VisitsSurveyList";
import getSurveyListByClinic from "./Api/getSurveyListByClinic";
import ScheduleAppointment from "./VisitsList/ScheduleAppointment";
import getLatestSurveyHistories from "./Api/getLatestSurveyHistories";
import SurveyConfirmation from "./VisitsList/SurveyConfirmation";
import VisitStatus from "./VisitsList/VisitStatus";
import getVisitStatus from "./Api/getVisitStatus";
import getForms from "./Api/getForms";
import scheduleSurveyCron from "./Api/scheduleSurveyCron";
import ConfirmScheduleModal from "./VisitsList/ConfirmScheduleModal";

const Visit = () => {
  const [clinicNames, setClinicNames] = useState([]);
  const [isclinicSelected, setIsclinicSelected] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const { isAuthorized, setRedirectUri, accessToken, userInfo } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;
  const [surveysList, setSurveysList] = useState();
  const [invalidEmail, setInvalidEmail] = useState("");
  const [formData, setFormData] = useState({
    isExpanded: false,
    showSelectedSurveys: false,
    showConfigureEmail: false,
    phoneTextMessage: "",
    emailTextMessage: "",
    notifyLoggedInUser: true,
    additionalPartyEmails: [],
    globalSurveys: [],
    clinicalSurveys: [],
    globalCheckBox: false,
    clinicalCheckBox: false,
  });
  const [loader, setLoader] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);
  const [surveyHistories, setSurveyHistories] = useState();
  const [error, setError] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [visitStatusList, setVisitStatusList] = useState([]);
  const [forms, setForms] = useState([]);
  const [showScheduleConfirmation, setShowScheduleConfirmation] = useState();
  useEffect(() => {
    if (!isAuthorized) {
      setRedirectUri(pathname);
      history.replace({
        pathname: "/",
      });
    } else {
      getClinic(accessToken)
        .then((response) => {
          setClinicNames(response);
        })
        .catch((err) => { });
      updateForms()
    }
  }, [isAuthorized]);
  const [visitLookupSearchParams, setVisitLookupSearchParams] = useState({
    daysLookingBack: new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
    daysLookingAhead: new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
    clinicId: "0",
    apptLocationId: "0",
    resourceId: "0",
    apptTypeId: "0",
    apptStatusId: "0",
    MRN: "0",
    ptName: "",
    cernerEnv: localStorage.getItem("cernerENV"),
  });
  const searchAppointments = () => {
    setSurveysList();
    setPatientList([]);
    setError(false);
    setLoader(true);
    visitLookupSearchParams.cernerEnv = localStorage.getItem("cernerENV");
    visitLookup(visitLookupSearchParams, accessToken)
      .then((response) => {

        setPatientList(response);
        if (response.length !== 0) {
          getSurveyListByClinic(visitLookupSearchParams.clinicId, accessToken)
            .then((response) => {
              setLoader(false);
              setSurveysList(response);
            })
            .catch((err) => {

              setLoader(false);
            });
        } else {
          setError(true);
          setLoader(false);
        }
      })
      .catch((err) => {

        setLoader(false);
      });
  };
  const submitSurveys = () => {
    const combinedSurveys = [...formData.globalSurveys];
    setScreenLoader(true);
    formData.clinicalSurveys.forEach((clinicalSurvey) => {
      const existingSurveyIndex = combinedSurveys.findIndex(
        (s) => s.pid === clinicalSurvey.pid
      );

      if (existingSurveyIndex > -1) {
        clinicalSurvey.instruments.forEach((instrument) => {
          if (
            !combinedSurveys[existingSurveyIndex].instruments.some(
              (i) => i.instrumentName === instrument.instrumentName
            )
          ) {
            combinedSurveys[existingSurveyIndex].instruments.push(instrument);
          }
        });
      } else {
        combinedSurveys.push(clinicalSurvey);
      }
    });
    var LatestSurveysList = combinedSurveys.flatMap((item) =>
      item.instruments.map((instrument) => instrument.instrumentName)
    );
    var patientList = [];
    var patientAndSurveysList = [];
    for (var i = 0; i < selectedPatients.length; i++) {
      patientList.push(selectedPatients[i].redcap_person_id);
      selectedPatients[i].appointment.appt_communication_method =
        selectedPatients[i].appointment.appt_communication_method.toLowerCase() === "email"
          ? "email"
          : "phone";
      selectedPatients[i].isSelected = true;
      selectedPatients[i].surveys = structuredClone(combinedSurveys);
      patientAndSurveysList.push(selectedPatients[i]);
    }
    setSelectedPatients(patientAndSurveysList);
    var requestBody = {
      personIds: patientList,
      instrumentNames: LatestSurveysList,
    };

    getLatestSurveyHistories(requestBody, accessToken)
      .then((response) => {
        setSurveyHistories(response);
        setScreenLoader(false);
        setShowConfirmationScreen(true);
      })
      .catch((err) => {

        setScreenLoader(false);
      });
  };
  const editSelectedPatients = (patient) => {
    const isPatientSelected = selectedPatients.some(
      (p) => p.redcap_person_id === patient.redcap_person_id
    );

    if (isPatientSelected) {
      setSelectedPatients(
        selectedPatients.filter(
          (item) => item.redcap_person_id !== patient.redcap_person_id
        )
      );
    } else {
      setSelectedPatients([...selectedPatients, patient]);
    }
  };

  const checkPatientExists = (patient) => {
    if (selectedPatients.lenght !== 0) {
      if (
        selectedPatients.some(
          (patients) => patients.redcap_person_id === patient.redcap_person_id
        )
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const addAllPatients = () => {
    if (selectedPatients.length === patientList.length) {
      setSelectedPatients([]);
    } else {
      setSelectedPatients(patientList);
    }
  };

  const checkAllpatients = () => {
    if (selectedPatients.length === patientList.length) {
      return true;
    } else {
      return false;
    }
  };
  const setVisitStatus = (searchParams) => {
    setScreenLoader(true);
    var lname = "";
    var fname = "";
    var mrn = "";
    
    if(searchParams.nameOrMrn){
      var nameOrMrn = searchParams.nameOrMrn;
      if (/^[0-9]+$/.test(nameOrMrn)) {
        mrn = nameOrMrn;
      } else {
        if (nameOrMrn.includes(", ")) {
          const nameParts = nameOrMrn.split(", ");
          lname = nameParts[0].toUpperCase();
          fname = nameParts[1].toUpperCase();
        } else if (nameOrMrn.includes(" ")) {
          const nameParts = nameOrMrn.split(" ");
          fname = nameParts[0].toUpperCase();
          lname = nameParts[1].toUpperCase();
        } else if (nameOrMrn.includes(",")) {
          const nameParts = nameOrMrn.split(",");
          lname = nameParts[0].toUpperCase();
          fname = nameParts[1].toUpperCase();
        } else {
          lname = nameOrMrn.toUpperCase();
        }
      }
    }
    searchParams.mrn = mrn;
    searchParams.firstName = fname;
    searchParams.lastName = lname;
    getVisitStatus(searchParams, accessToken)
      .then((response) => {
        setVisitStatusList(response);
        setScreenLoader(false);
      })
      .catch((err) => {
        setScreenLoader(false);
      });
  };
  const updateForms = () => {
    getForms(accessToken)
      .then((response) => {
        setForms(response);
      })
      .catch((err) => { });
  };

  const isButtonDisabled = () => {
    if (selectedPatients) {
      if (
        (formData.globalSurveys.length === 0 &&
          formData.clinicalSurveys.length === 0) ||
        selectedPatients.length === 0
      ) {
        return true;
      }
    }
    return false;
  };

  function filterPatientsWithActiveSurveys(patients) {
    const filteredPatients = patients.filter((patient) => {
      if (patient.isSelected === false) {
        return false;
      }
      patient.surveys = patient.surveys.filter((survey) => {
        survey.instruments = survey.instruments.filter(
          (instrument) => instrument.status === 1
        );
        return survey.instruments.length > 0;
      });
      return patient.surveys.length > 0;
    });
    return filteredPatients;
  }

  const onClickSendSurvey = (scheduledDatetime) => {
    var filteredPatients = filterPatientsWithActiveSurveys(selectedPatients);
    const options = { timeZone: "America/Los_Angeles", hour12: true };
    const pstDate = scheduledDatetime.toLocaleString("en-US", options);
    var requestBody = {
      patients: filteredPatients,
      notifyLoggedInUser: formData.notifyLoggedInUser,
      loggedInUsername: userInfo.email,
      additionalPartyEmails: formData.additionalPartyEmails.join(","),
      scheduledDatetime: pstDate,
    };
    scheduleSurveyCron(requestBody, accessToken)
      .then((response) => {

        window.location.reload();
      })
      .catch((err) => {

      });
    setScreenLoader(true);
  };

  const setSurveyName = (inputString) => {
    var data = forms.find(form => form.instrumentName === inputString);
    if (data) {
      return data.instrumentLabel;
    }
    return convertString(inputString);
  };

  const convertString = (inputString) => {
    return inputString
      .replace(/_/g, ' ')
      .replace(/\b\w/g, char => char.toUpperCase());
  }

  return (
    <>
      {screenLoader && (
        <div id="overlay">
          <div id="loading" className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      )}
      <VisitList
        clinicNames={clinicNames}
        setSearchParams={setVisitLookupSearchParams}
        isclinicSelected={isclinicSelected}
        setIsclinicSelected={setIsclinicSelected}
        searchParams={visitLookupSearchParams}
        searchAppointments={searchAppointments}
        loader={loader}
        showSchedule={showSchedule}
        showConfirmationScreen={showConfirmationScreen}
        showStatus={showStatus}
        setStatus={setVisitStatus}
        forms={forms}
      />
      {!showSchedule && !showConfirmationScreen && !showStatus && (
        <div
          className="row"
          style={{ marginLeft: "15px", marginRight: "15px" }}
        >
          <div
            className="col-sm-12 d-lg-none"
            style={{ paddingRight: "0px", paddingLeft: "0px" }}
          >
            {surveysList && surveysList.lenght !== 0 && (
              <VisitsSurveyList
                formData={formData}
                setFormData={setFormData}
                surveysList={surveysList}
                submitSurveys={submitSurveys}
                isButtonDisabled={isButtonDisabled}
                invalidEmail={invalidEmail}
                setInvalidEmail={setInvalidEmail}
              />
            )}
          </div>
          <div className="col-lg-9 col-sm-12" style={{ padding: "0" }}>
            <div>
              {isclinicSelected && (
                <AppointmentDateSelection
                  setSearchParams={setVisitLookupSearchParams}
                  searchParams={visitLookupSearchParams}
                  searchResults={patientList}
                  selectedPatients={selectedPatients}
                  addAllPatients={addAllPatients}
                  checkAllpatients={checkAllpatients}
                  editSelectedPatients={editSelectedPatients}
                  checkPatientExists={checkPatientExists}
                  setShowSchedule={setShowSchedule}
                  setShowStatus={setShowStatus}
                  setSelectedPatients={setSelectedPatients}
                />
              )}
            </div>
          </div>
          <div
            className="col-lg-3 d-none d-lg-block"
            style={{ paddingRight: "0" }}
          >
            {surveysList && surveysList.lenght !== 0 && (
              <VisitsSurveyList
                formData={formData}
                setFormData={setFormData}
                surveysList={surveysList}
                submitSurveys={submitSurveys}
                isButtonDisabled={isButtonDisabled}
                invalidEmail={invalidEmail}
                setInvalidEmail={setInvalidEmail}
              />
            )}
          </div>
          {error && (
            <div
              className="col-lg-12 col-sm-12 d-none d-lg-block"
              style={{ margin: "15px" }}
            >
              <span style={{ color: "red" }}>No Patients found .</span>
            </div>
          )}
        </div>
      )}
      {showConfirmationScreen && (
        <SurveyConfirmation
          patientsList={selectedPatients}
          setShowConfirmationScreen={setShowConfirmationScreen}
          surveyHistories={surveyHistories}
          globalSurveys={surveysList.generalProjects}
          setPatientList={setSelectedPatients}
          onClickSendSurvey={onClickSendSurvey}
          setShowScheduleConfirmation={setShowScheduleConfirmation}
        />
      )}
      {showSchedule && (
        <ScheduleAppointment setShowSchedule={setShowSchedule} />
      )}
      {showStatus && (
        <VisitStatus
          setVisitStatus={setVisitStatus}
          setShowStatus={setShowStatus}
          surveyList={visitStatusList}
          setSurveyName={setSurveyName}
        />
      )}
      {showScheduleConfirmation && (
        <ConfirmScheduleModal
          onClickSendSurvey={onClickSendSurvey}
          setShowScheduleConfirmation={setShowScheduleConfirmation}
        />
      )}
    </>
  );
};

export default Visit;
