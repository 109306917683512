import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Logout = () => {
    const history = useHistory()
    useEffect(() => {
        history.replace({
            pathname: "/",
        });
    })
  return (
    <div></div>
  )
}

export default Logout