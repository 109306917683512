import React, { useState, useRef, useEffect } from "react";
import "./patients.css";
import DatePicker from "react-datepicker";
import getPatient from "./Api/getPatients";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import PatientsList from "./patientsList";
import { Spinner } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth } from './AppContextProvider';

const Home = () => {
  const location = useLocation();
  const history = useHistory();
  const List = location.state;
  const pathname = location.pathname;
  const [list, setList] = useState();
  const [loader, setloader] = useState(false);
  const { isAuthorized, setRedirectUri, accessToken } = useAuth();
  const [formData, setFormData] = useState({
    nameOrMrn: "",
    fin: "",
    dob: "",
  });
  const datepickerRef = useRef(null);
  const openDatePicker = () => {
    // Programmatically trigger the date picker's focus
    datepickerRef.current.setOpen(true);
  };

  useEffect(() => {
    if(!isAuthorized){
      setRedirectUri(pathname);
      history.replace({
        pathname: "/",
      });
    }
  }, [isAuthorized])

  useEffect(() => {
    const handleBeforeUnload = () => {
      history.replace({
        pathname: "/",
      });
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (List != undefined) {
      setList(List.List);
    }
  }, [List]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };
  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dob: date,
    });
  };

  const handleSearch = () => {
    setList([]);
    var lname = "";
    var fname = "";
    var mrn = "";
    const formattedDob = formData.dob
      ? formData.dob.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : "";

    if (/^[0-9]+$/.test(formData.nameOrMrn)) {
      mrn = formData.nameOrMrn;
    } else {
      if (formData.nameOrMrn.includes(", ")) {
        const nameParts = formData.nameOrMrn.split(", ");
        lname = nameParts[0].toUpperCase();
        fname = nameParts[1].toUpperCase();
      } else if (formData.nameOrMrn.includes(" ")) {
        const nameParts = formData.nameOrMrn.split(" ");
        fname = nameParts[0].toUpperCase();
        lname = nameParts[1].toUpperCase();
      } else if (formData.nameOrMrn.includes(",")) {
        const nameParts = formData.nameOrMrn.split(",");
        lname = nameParts[0].toUpperCase();
        fname = nameParts[1].toUpperCase();
      } else {
        lname = formData.nameOrMrn.toUpperCase();
      }
    }
    const searchData = {
      lname: lname,
      fname: fname,
      fin: formData.fin,
      dob: formattedDob !== ""? formattedDob.replaceAll("/","")+" 000000" : "",
      mrn: mrn,
      cernerEnv: localStorage.getItem('cernerENV')
    };
    setloader(true);
    getPatient(searchData, accessToken)
      .then((response) => {
        
        setloader(false);
        setList(response);
      })
      .catch((err) => {
        setloader(false);
        
      });
    
  };

  return (
    <>
      <div id="patient_information">
        <div className="row piTitle container-fluid">
          <div className="col-lg-6 col-sm-12">Patient Information</div>
        </div>

        <div className="piInputs container-fluid justify-content-left">
          <form>
            <div className="row d-flex justify-content-left">
              <div className="col-lg-3 loolkupinput col-md-12 col-sm-12 d-flex justify-content-center justify-content-sm-start mb-2">
                <div className="finInput force-opaque">
                  <input
                    placeholder="Search by Name or MRN"
                    style={{ border: "none", width: "100%" }}
                    type="text"
                    id="nameOrMrn"
                    autoComplete="off"
                    value={formData.nameOrMrn}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 loolkupinput col-md-12 col-sm-12 d-flex justify-content-center justify-content-sm-start mb-2">
                <div className="finInput force-opaque">
                  <input
                    style={{ border: "none", width: "100%" }}
                    placeholder="Search by FIN"
                    type="number"
                    id="fin"
                    autoComplete="off"
                    value={formData.fin}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-lg-3 loolkupinput col-md-12 col-sm-12 d-flex justify-content-center justify-content-sm-start mb-2">
                <div className="dobBody">
                  <div className="dob">DOB: </div>
                  <DatePicker
                    selected={formData.dob}
                    onChange={handleDateChange}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                    className="custom-datepicker"
                    ref={datepickerRef}
                    maxDate={new Date()}
                  />
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="calendar-icon"
                    onClick={openDatePicker}
                  />
                </div>
              </div>
              <div
                className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-center"
                style={{ alignSelf: "center" }}
              >
                <div className="row w-100 m-0 p-0 d-flex justify-content-center">
                  <div className="col-sm-4 mb-2 col-md-4 col-lg-12 m-0 p-0 d-flex justify-content-center">
                    <button
                      className="searchButton  d-flex justify-content-around"
                      id="searchButton"
                      type="submit"
                      onClick={handleSearch}
                      disabled = {loader || (formData.dob === "" && formData.nameOrMrn === "" && formData.fin === "")}
                    >
                      <div id="SearchPatient">
                        {loader ? <Spinner/> : "Search Patient"}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {<PatientsList List={list} loader = {loader} fin={formData.fin} setList={setList}/>}
    </>
  );
};

export default Home;
