import React, { useState, useRef, useEffect } from "react";
import "../patients.css";
import { useOktaAuth } from "@okta/okta-react";
import { useLocation, useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FormData from "../Api/FormData.js";
import Chevron from "../images/chevron.png";
import dChevron from "../images/chevron_d.png";
import Instruments from "./instruments";

function Forms(props) {
  const {formData} = props;
  const [show, setShow] = useState(false);
  const [fchevron, setfchevron] = useState(false);
  // const [selectedInstruments, setSelectedInstruments] = useState([]);
  // useEffect(() => {
  //   setSelectedInstruments(selectedInstruments);
  // }, [selectedInstruments]);
  return (
    <>
      <div className="formsHeder">
        <div
          className="formsBody"
          onClick={() => {
            setShow(!show);
            setfchevron(!fchevron);
          }}
        >
          <div>General forms</div>{" "}
          {fchevron ? (
            <img className="chevron" src={dChevron} />
          ) : (
            <img className="chevron" src={Chevron} />
          )}
        </div>
        {show ? (
          formData.projects.map((data) => {
            return (
              <>
                <Instruments data={data} FormData = {formData} alterChosenForms = {props.alterChosenForms} recordId = {props.recordId} isSurveySelected={props.isSurveySelected} chosenForms = {props.chosenForms}/>
              </>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
export default Forms;
