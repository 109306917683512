import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import PreferredContactModal from "./PreferredContactModal";
import PhoneValidation from "../../Util/PhoneValidation";
import EmailValidation from "../../Util/EmailValidation";

const VisitLookupPatients = (props) => {
  const {
    patientsDetail,
    selectedPatients,
    editSelectedPatients,
    checkPatientExists,
    setSelectedPatients,
  } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const initialPopupDetails = {
    preferredContact:
      patientsDetail.appointment.appt_communication_method.toLowerCase() ===
      "email"
        ? "Email"
        : "Phone",
    email: patientsDetail.appointment.appt_communication_email,
    phone: patientsDetail.appointment.appt_communication_number,
  };
  const [popUpContactDetails, setPopUpContactDetails] = useState(initialPopupDetails);
  const handleCheckboxChange = () => {
    editSelectedPatients(patientsDetail);
  };
  useEffect(() => {
    setIsChecked(checkPatientExists(patientsDetail));
  }, [selectedPatients]);
  const onClickContacts = () => {
    setShowPopup(true);
  };
  const onCancel = () => {
    setShowPopup(false);
    setPopUpContactDetails(initialPopupDetails);
  }
  const onSave = () => {
    
    if (
      popUpContactDetails.preferredContact.toLowerCase() === "email" &&
      !EmailValidation(popUpContactDetails.email)
    ) {
      alert("Please enter a valid mail id!");
      return;
    } else if (
      popUpContactDetails.preferredContact.toLowerCase() !== "email" &&
      !PhoneValidation(popUpContactDetails.phone)
    ) {
      alert("Please enter a valid phone number!");
      return;
    }
    if (checkPatientExists(patientsDetail)) {
      const patientIndex = selectedPatients.findIndex(
        (patient) =>
          patient.redcap_person_id === patientsDetail.redcap_person_id
      );
      selectedPatients[patientIndex].appointment.appt_communication_email =
        popUpContactDetails.email;
      selectedPatients[patientIndex].appointment.appt_communication_number =
        popUpContactDetails.email;
      selectedPatients[patientIndex].appointment.appt_communication_method =
        popUpContactDetails.email;
      setSelectedPatients(selectedPatients);
    }
    patientsDetail.appointment.appt_communication_email =
      popUpContactDetails.email;
    patientsDetail.appointment.appt_communication_number =
      popUpContactDetails.phone;
    patientsDetail.appointment.appt_communication_method =
      popUpContactDetails.preferredContact;
    setShowPopup(false);
  };
  useEffect(() => {}, [patientsDetail]);
  return (
    <>
      {showPopup && (
        <PreferredContactModal
          onCancel={onCancel}
          popUpDetails={popUpContactDetails}
          setPopUpDetails={setPopUpContactDetails}
          onSave={onSave}
        />
      )}
      <div className="m-0 row d-flex justify-content-center align-item-center p-3 patientData">
        <div className="custom-lg-1 customCheckboxColumn col-md-12 col-sm-12 d-flex justify-content-left mb-1">
          <span class="d-flex" />{" "}
          <input
            type="checkbox"
            className="PatientCheckbox"
            id="PatientCheckbox"
            name="Locked"
            style={{ height: "25px", width: "25px" }}
            onChange={handleCheckboxChange}
            checked={isChecked}
          />{" "}
        </div>
        <div className="custom-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
          <span
            className="d-lg-none col-md-3 col-sm-3"
            style={{ color: "black" }}
          >
            Time :
          </span>
          <span className="patientsinfo col-sm-6 col-lg-12 m-0 p-0 ">
            {patientsDetail.appointment.appt_start_dt_tm}
          </span>
        </div>
        <div className="custom-lg-2 customPatientColumn col-md-12 col-sm-12 d-flex justify-content-left mb-1 ">
          <span
            className="d-lg-none col-md-3 col-sm-3"
            style={{ color: "black" }}
          >
            Patient :
          </span>
          <span className="patientsinfo col-sm-6 col-lg-12 m-0 p-0 ">
            {patientsDetail.patient_name} {patientsDetail.MRN}{" "}
            {patientsDetail.sex}
          </span>
        </div>
        <div className="custom-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
          <span
            className="d-lg-none col-md-3 col-sm-3"
            style={{ color: "black" }}
          >
            Age :
          </span>
          <span className="patientsinfo col-sm-6 col-lg-12 m-0 p-0 ">
            {patientsDetail.age}
          </span>
        </div>
        <div className="custom-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
          <span
            className="d-lg-none col-md-3 col-sm-3"
            style={{ color: "black" }}
          >
            Resource :
          </span>
          <span className="patientsinfo col-sm-6 col-lg-12 m-0 p-0 ">
            {patientsDetail.appointment.resource}
          </span>
        </div>

        <div className="custom-lg-2 customDetailsColumn col-md-12 col-sm-12 d-flex justify-content-left mb-1">
          <span
            className="d-lg-none col-md-3 col-sm-3"
            style={{ color: "black" }}
          >
            Details :
          </span>
          <span className="patientsinfo col-sm-6 col-lg-12 m-0 p-0 ">
            {patientsDetail.appointment.appt_type}
          </span>
        </div>

        <div className="custom-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
          <span
            className="d-lg-none col-md-3 col-sm-3"
            style={{ color: "black" }}
          >
            Status :
          </span>
          <span className="patientsinfo col-sm-6 col-lg-12 m-0 p-0 apStatus">
            {patientsDetail.appointment.appt_status}
          </span>
        </div>
        <div
          class="custom-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1 apEmail"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
          }}
        >
          <span
            className="d-lg-none col-md-3 col-sm-3"
            style={{ color: "black" }}
          >
            Contacts :
          </span>
          <div
            style={{ cursor: "pointer" }}
            class="patientsinfo col-sm-6 col-lg-12 m-0 p-0"
          >
            <div class="iconParent ">
              <div class="contact v_ellipsis" onClick={onClickContacts}>
                {patientsDetail.appointment.appt_communication_number}
              </div>
              {(patientsDetail.appointment.appt_communication_method ===
                "Text" ||
                patientsDetail.appointment.appt_communication_method ===
                  "Text (Enter number below)" ||
                patientsDetail.appointment.appt_communication_method.toLowerCase() ===
                  "phone") && (
                <FontAwesomeIcon
                  icon={faStar}
                  style={{ fontSize: "20px", color: "#004879" }}
                />
              )}
            </div>
            <div class="iconParent">
              <div class="contact v_ellipsis" onClick={onClickContacts}>
                {patientsDetail.appointment.appt_communication_email}
              </div>
              {patientsDetail.appointment.appt_communication_method.toLowerCase() ===
                "email" && (
                <FontAwesomeIcon
                  icon={faStar}
                  style={{ fontSize: "20px", color: "#004879" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VisitLookupPatients;
