import React, { createContext, useState, useContext, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import secrets from '../env.json';

// Create a context
const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [userAccessStatus, setUserAccessStatus] = useState("");
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const setIsAuthorizedState = (val) => {
    setIsAuthorized(val);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [redirectUri, setRedirectUri] = useState("");

  if (localStorage.getItem("cernerENV") === null) {
    localStorage.setItem("cernerENV", `${secrets.REACT_APP_CERNER_ENV}`);
  }
  useEffect(() => {
    if (!authState?.isAuthenticated) {
      setIsAuthenticated(false);
      // When user isn't authenticated, forget any user info
    } else {
      setAccessToken(authState.accessToken.accessToken);
      setIsAuthenticated(true);
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState]);
  return (
    <AuthContext.Provider
      value={{
        isAuthorized,
        setIsAuthorizedState,
        accessToken,
        userInfo,
        isAuthenticated,
        redirectUri,
        setRedirectUri,
        userAccessStatus,
        setUserAccessStatus
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
