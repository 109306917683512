import React, { useState, useEffect } from "react";
import "../../patientsList.css";
import { Accordion } from "react-bootstrap-accordion";
import "react-bootstrap-accordion/dist/index.css";
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  Switch,
} from "react-router-dom";
import getPatientHistory from "../../Api/getPatientHistory";
import { useAuth } from "../../AppContextProvider";
import { getAge } from "../../../Util/PatientDetailUtil";


function AppointmentListData(props) {
  const [Appointment, setAppointment] = useState(props.Appointment);
  const [loader, setloader] = useState(false);
  const history = useHistory();
  const {accessToken} = useAuth();
  useEffect(() => {
    setAppointment(props.Appointment);
  }, [props.Appointment]);
  
  const getPatientSurveyHistoryData = () => {
    setloader(true);
    var updatedPatientData = getPatientsDataWithAppointment();
    var requestBody = {
      recordId : props.patientData.record_id,
      age : getAge(props.patientData.birth_date),
      gender : props.patientData.sex,
      locationType : updatedPatientData.currentEncounter ? updatedPatientData.currentEncounter[0].location_facility == "CHLA" ? "inPatient" : "outPatient" : "",
    }
    getPatientHistory(requestBody, accessToken)
    .then((response) => {
      
      setloader(false);
      var patientData = getPatientsDataWithAppointment();
      var currentSelectionType = "appointment";
      if(patientData.currentEncounter && patientData.currentEncounter.length > 0){
        currentSelectionType = "encounter";
      }
      history.replace({
        pathname: "/PatientDetails",
        state: {
          patientData: patientData,
          List: props.List, // Replace with your list of values,
          surveyData: response,
          selectionType: currentSelectionType,
          fin : ""
        }
      });
    })
  }

  const getDates = (data) => {
    if (data === undefined) {
      return { date: "", age: "" };
    }

    const birthMonth = data.slice(0, 2);
    const birthDay = data.slice(2, 4);
    const birthYear = data.slice(4, 8);

    const today = new Date();
    const birthDate = new Date(`${birthYear}-${birthMonth}-${birthDay}`);

    const ageInMilliseconds = today - birthDate;
    const ageInYears = Math.floor(ageInMilliseconds / 31536000000); // Approximate milliseconds in a year

    return {
      date: `${birthMonth}/${birthDay}/${birthYear}`,
      age: ageInYears + " yrs",
      dateFormat: data, // Added for "mmddyyyy" format
    };
  };

  function getObjectByFinEncounter(encounterList, finEncounter) {
    for (let i = 0; i < encounterList.length; i++) {
        if (encounterList[i].fin_encounter === finEncounter) {
            return encounterList[i];
        }
    }
    return null;
  }

  const getPatientsDataWithAppointment = () => {
    var appointment = [];
    appointment.push(Appointment);

    // Clone the PatientData object
    var clonedPatientData = JSON.parse(JSON.stringify(props.patientData));

    // Modify the cloned object by adding the Encounter array
    clonedPatientData.currentAppointment = appointment;
    if(appointment.fin_appointment && appointment.fin_appointment !== ""){
      clonedPatientData.currentEncounter = getObjectByFinEncounter(clonedPatientData.encounter, appointment.fin_appointment);
    }

    return clonedPatientData;
  };

  return (
    <>
    {loader ? 
    <div id="overlay">
      <div id="loading" class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div> : <></>}
    <div
      className="row d-flex justify-content-center p-3 patientFIN"
      style={{ margin: "auto" }}
    >
      <div
        className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
        style={{ color: "black" }}
      >
        <span className="d-lg-none">Appointment Location:&nbsp;</span>
        <span className="patientsinfo apEmail">
          {Appointment.appt_location_unit}
        </span>
      </div>
      <div
        className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
        style={{ color: "black" }}
      >
        <span className="d-lg-none">Appointment Type:&nbsp;</span>
        <span className="patientsinfo apEmail">{Appointment.appt_type}</span>
      </div>
      <div
        className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
        style={{ color: "black" }}
      >
        <span className="d-lg-none">Appointment Status:&nbsp;</span>
        <span className="patientsinfo">&nbsp;{Appointment.appt_status}</span>
      </div>
      <div
        className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
        style={{ color: "black" }}
      >
        <span className="d-lg-none">Medical Service:&nbsp;</span>
        <span className="patientsinfo">&nbsp;--</span>
      </div>
      <div
        className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
        style={{ color: "black" }}
      >
        <span className="d-lg-none">Appointment Date:&nbsp;</span>
        <span className="patientsinfo">
          &nbsp;{getDates(Appointment.appt_start_dt_tm).date}
        </span>
      </div>
      <div className="col-lg-2 col-md-4 col-sm-4 d-flex justify-content-left">
        <button className="selectPatient"  onClick={(e) => {
              getPatientSurveyHistoryData();
            }} >Select Appointment</button>
      </div>
    </div>
    </>
  );
}
export default AppointmentListData;
