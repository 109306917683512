import React, { useState, useEffect } from "react";
import "./patientsList.css";
import "react-bootstrap-accordion/dist/index.css";
import PatientsData from "./PatientsListData/patientsData";
function PatientsList(props) {
  const [patientsSearchData, setpatientsSearchData] = useState(props.List);
  useEffect(() => {
    setpatientsSearchData(props.List);
  }, [props.List]);

  const onReset = () => {
    setpatientsSearchData(undefined);
  }

  return (
    <div className="m-3 finlist">
      <div className="row justify-content-end">
        <div className="col-lg-12 col-md-12 col-sm-12">
          {(patientsSearchData && patientsSearchData.length > 0) && 
            <div className="reset">
              <span onClick={onReset} style={{cursor:"pointer"}}>Reset</span>
            </div>
          }
        </div>
      </div>
      {props.loader ? 
      <span style={{color:"#69a3dc"}}>Searching...</span>
      :
      <>
        {!patientsSearchData && 
          <span style={{color:"#69a3dc"}}>Please enter the patient's FIN, MRN, Name or DOB</span>
        }
        {patientsSearchData?.length === 0 &&
         <span style={{color:"red"}}>Patient not found. Please Check your Inputs .</span>
        }
      </>}
      { (patientsSearchData && patientsSearchData?.length !== 0) && 
        <div className="row d-none d-lg-block">
          <div className="col-lg-12 col-md-12 col-sm-12 prow">
            <div className="row justify-content-center align-item-center p-3 patientDataTop">
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop"> NAME </span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop">MRN</span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop">DOB</span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop">AGE</span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop"></span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop"></span>
              </div>
            </div>
          </div>
        </div>
      }
      {patientsSearchData && (
        patientsSearchData.map((patientData, i) => {
          return <PatientsData List={props.List} patientData={patientData} fin={props.fin} setList={props.setList}/>;
        })
      )}
    </div>
  );
}

export default PatientsList;
