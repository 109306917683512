import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import secrets from './env.json';
const oktaConfig = new OktaAuth({
  issuer: secrets.REACT_APP_OKTA_ISSUER,
  redirectUri: `${window.location.origin}/login/callback`,
  clientId: secrets.REACT_APP_CLIENT_ID,
  scopes: ["openid", "profile", "email", "REDCap:user"],
});

export default oktaConfig;
