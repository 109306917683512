import React from "react";

// "123-456-7890",
// "(123) 456-7890",
// "123 456 7890",
// "123.456.7890",
// "+91 (123) 456-7890",
// "+911234567890",
// "1234567890",
// "+121234567890"
// the Regix is created to Match these formats
const regex = new RegExp(
  /^(\+\d{1,3}\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/
);

// Basic phone number validation
// const regex = new RegExp(/^\+(?:[0-9] ?){6,14}[0-9]$/);

// International Phone number validation
// const regex = new RegExp(
//   /^(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})$/
// );

const PhoneValidation = (phoneNumber) => {
    return regex.test(phoneNumber);
};

export default PhoneValidation;
