import React, { useState, useEffect } from "react";
import "../patients.css";
import { useOktaAuth } from "@okta/okta-react";
import { useLocation, useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FormData from "../Api/FormData.js";
import Chevron from "../images/chevron.png";
import dChevron from "../images/chevron_d.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function HistoryList(props) {
  const [history, setHistory] = useState(props.HistoryData);

  useEffect(() => {
    setHistory(props.HistoryData);
  }, [props.HistoryData]);

  return (
    <div style={{padding:"0px"}}>
      {history?.map((c, index) => (
        <div className="row m-0 d-flex justify-content-space-between py-3 px-1" key={index}>
          <div className="col-lg-1 col-md-12 col-sm-12 d-flex justify-content-left tableCell " style={{ color: 'black'}}>
            <span className="d-lg-none patientsinfoTop" style={{ color: "black", whiteSpace:"nowrap" }}>Status:&nbsp;</span>
            <span className="patientsinfo apEmail">{c.statusUcRedcapSurveyHistory}</span>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left tableCell " style={{ color: 'black' }}>
            <span className="d-lg-none patientsinfoTop" style={{ color: "black", whiteSpace:"nowrap", overflow: 'hidden'}}>Sent Date:&nbsp;</span>
            <span className="patientsinfo">{c.sentDtTmUcRedcapSurveyHistory}</span>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left tableCell " style={{ color: 'black' }}>
            <span className="d-lg-none patientsinfoTop" style={{ color: "black", whiteSpace:"nowrap" }}>Date Modified:&nbsp;</span>
            <span className="patientsinfo">{c.dateModifiedDtTmUcRedcapSurveyHistory}</span>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left tableCell " style={{ color: 'black' }}>
            <span className="d-lg-none patientsinfoTop" style={{ color: "black", whiteSpace:"nowrap" }}>Email Used:&nbsp;</span>
            <span className="patientsinfo apEmail" >{c.sentEmailUcRedcapSurveyHistory}</span>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left tableCell " style={{ color: 'black' }}>
            <span className="d-lg-none patientsinfoTop" style={{ color: "black", whiteSpace:"nowrap" }}>Phone Number Used:&nbsp;</span>
            <span className="patientsinfo">{c.phoneNumberUcRedcapSurveyHistory}</span>
          </div>
          <div className="col-lg-1 col-md-12 col-sm-12 d-flex justify-content-left tableCell " style={{ color: 'black' }}>
            <span className="d-lg-none patientsinfoTop" style={{ color: "black", whiteSpace:"nowrap" }}>Sent By:&nbsp;</span>
            <span className="patientsinfo loggegInUserEmail" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>{c.loggedInUserUcRedcapSurveyHistory}</span>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left pl-4" style={{ color: 'black' }}>
            <span className="d-lg-none patientsinfoTop" style={{ color: "black", whiteSpace:"nowrap" }}>Additional Party:&nbsp;</span>
            <span className="patientsinfo apEmail">
              {c.additionalPartyUcRedcapSurveyHistory && 
                <>{c.additionalPartyUcRedcapSurveyHistory.replaceAll(',', ', ')}</>
              }
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default HistoryList;
