import React, { useState, useEffect } from "react";
import "../../patientsList.css";
import { Accordion } from "react-bootstrap-accordion";
import "react-bootstrap-accordion/dist/index.css";
import AppointmentTable from "../Appointment/appoitnmentTable";
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  Switch,
} from "react-router-dom";
import getPatientHistory from "../../Api/getPatientHistory";
import { useAuth } from "../../AppContextProvider";
import { getAge } from "../../../Util/PatientDetailUtil";

function EncounterListData(props) {
  const [PatientData, setpatientData] = useState(props.patientData);
  const [encounter, setencounter] = useState(props.Encounter);
  const [appointment, setAppointment] = useState(false);
  const [loader, setloader] = useState(false);
  const history = useHistory();
  const {accessToken} = useAuth();

  useEffect(() => {
    setencounter(props.Encounter);
    setpatientData(props.patientData);
  }, [props.Encounter]);

  const getPatientSurveyHistoryData = () => {
    setloader(true);
    var updatedPatientData = getPatientsDataWithEncounter();
    var locationType = updatedPatientData.currentEncounter[0].location_facility == "CHLA" ? "inPatient" : "outPatient";
    var requestBody = {
      recordId : PatientData.record_id,
      age : getAge(PatientData.birth_date),
      gender : PatientData.sex,
      locationType : locationType,
    }
    getPatientHistory(requestBody, accessToken)
    .then((response) => {
      if(props.isEncounterList){
        props.alterEncounterSurvey(true, locationType);
      }
      setloader(false);
      if(props.isEncounterList){
        props.setDetailsLoader(false);
      }
      history.replace({
        pathname: "/PatientDetails",
        state: {
          patientData: updatedPatientData,
          List: props.List, // Replace with your list of values,
          surveyData: response,
          selectionType: "encounter",
          fin : ""
        }
      });
    })
  }
  const getDates = (data) => {
    if (data === undefined || data === "") {
      return {
        date: "--",
        age: "",
        dateFormat: data
      };
    }

    const birthMonth = data.slice(0, 2);
    const birthDay = data.slice(2, 4);
    const birthYear = data.slice(4, 8);

    const today = new Date();
    const birthDate = new Date(`${birthYear}-${birthMonth}-${birthDay}`);

    const ageInMilliseconds = today - birthDate;
    const ageInYears = Math.floor(ageInMilliseconds / 31536000000); // Approximate milliseconds in a year

    return {
      date: `${birthMonth}/${birthDay}/${birthYear}`,
      age: ageInYears + " yrs",
      dateFormat: data, // Added for "mmddyyyy" format
    };
  };
  
  const getPatientsDataWithEncounter = () => {
    var Encounter = [];
    Encounter.push(encounter);

    // Clone the PatientData object
    var clonedPatientData = JSON.parse(JSON.stringify(PatientData));

    // Modify the cloned object by adding the Encounter array
    clonedPatientData.currentEncounter = Encounter;

    return clonedPatientData;
  };

  return (
    <>
      {loader ? 
      <div id="overlay">
        <div id="loading" class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div> : <></>}
      <div className="row m-0 d-flex justify-content-center p-3 patientFIN">
        <div
          className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
          style={{ color: "black" }}
        >
          <span className="d-lg-none">FIN:&nbsp;</span>
          <span className="patientsinfo">{encounter.fin_encounter}</span>
        </div>
        <div
          className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
          style={{ color: "black" }}
        >
          <span className="d-lg-none">REG:&nbsp;</span>
          <span className="patientsinfo">
            {getDates(encounter.registration_date).date}
          </span>
        </div>
        <div
          className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
          style={{ color: "black" }}
        >
          <span className="d-lg-none">DIS:&nbsp;</span>
          <span className="patientsinfo">
            {getDates(encounter.discharge_dt_tm).date}
          </span>
        </div>
        <div
          className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
          style={{ color: "black" }}
        >
          <span className="d-lg-none Encountertype text-truncate" style={{whiteSpace: "normal"}}>
            MEDICAL SERVICE:&nbsp;
          </span>
          <span className="patientsinfo Encountertype apEmail">
            {encounter.medical_service}
          </span>
        </div>
        <div className={ (props.isEncounterList?"col-lg-4 col-md-12 col-sm-12":"col-lg-2 col-md-4 col-sm-4")+"col-md-4 col-sm-4 d-flex justify-content-center justify-content-md-center"}>
          <button
            className="selectPatient"
            style={props.isEncounterList?{backgroundColor:"#004879", color:"#eeeeee"}:{}}
            onClick={(e) => {
              
              if(props.isEncounterList){
                props.setShowEncounterList(false);
                props.setDetailsLoader(true);
              }
              getPatientSurveyHistoryData();
            }}
          >
            Select Encounter
          </button>
        </div>
        {!props.isEncounterList ? 
        <>
        {encounter.appointments.length > 0 ? (
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex justify-content-center text-center">
            <a
              className="selectvisits text-white"
              data-toggle="collapse"
              onClick={(e) => {
                setAppointment(!appointment);
              }}
            >
              Show Appointment {appointment ? "▼" : "▲"}
              {/* <span style={{ fontSize: "10px" }} className="Des">
              ▲
            </span> */}
            </a>
          </div>
        ) : (
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex justify-content-center text-center"></div>
        )}
        </>:
        <div className="col-lg-2 col-md-4 col-sm-4 d-flex justify-content-center text-center"></div>}
      </div>

      {appointment ? (
        <AppointmentTable
          List={props.List}
          patientData={getPatientsDataWithEncounter()}
          appointmentList={encounter.appointments}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default EncounterListData;
